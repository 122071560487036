import React from "react"

const Logo = ({ color = "#000" }) => (
  <svg
    id="logo"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 797.1 296.7"
    xmlSpace="preserve"
  >
    <g id="XMLID_2_" fill={color}>
      <path
        id="XMLID_3_"
        d="M447.9,265.3c0,0.6-0.2,1.2-0.8,1.6c-0.8,0.4-1.4,0.2-1.8-0.2l-1.4-1.8c-2.9-4.7-7.6-6.8-13.1-7.8
		c-2-0.4-3.9-0.8-5.9-1c-3.5-0.2-7.2-0.6-10.7-0.8h-13.7c-5.5,0.2-11.1,0.8-16.8,1c-2.5,0.2-5.1,0.6-7.4,0.8
		c-7.2,0.4-14.2,1.2-21.3,2.1l-22.4,2.9c-7.8,1.2-15.4,2.3-23,3.7c-10.3,1.8-20.5,3.7-30.6,5.7c-14.6,2.9-29.1,6-43.5,9.6
		c-7.8,2-15.4,3.7-23.2,5.5c-3.7,0.8-7.4,1.8-11.1,2.3c-6.4,1.2-12.9,2.1-19.1,2.1c-7-0.2-12.7-2.5-17.4-6.8
		c-2.9-2.9-4.5-6.6-5.1-10.7c-0.4-3.7-0.4-7.4,0.4-11.3c0.6-3.7,1.2-7.6,2-11.3c0.2-0.8,0.2-1.8,0.6-2.5c1.8-6.2,3.3-12.7,5.3-18.9
		c1.8-5.3,3.5-10.7,5.5-16.2c2.5-7.4,5.5-14.8,8.2-22.3c2.3-6.4,4.9-12.9,7.2-19.5l2.3-5.5c1.8-4.3,3.3-8.6,5.1-12.9l6.4-16.4
		c3.1-7.6,6.1-15.4,9-23l4.1-10.1c2-4.7,3.7-9.2,5.7-14.1c2-5.1,3.7-10.3,5.5-15.4c2.3-7,4.7-14.1,6.4-21.1c1-4.5,1.8-8.8,2.1-13.3
		c0.2-1,0.2-2.1,0-3.1c0-0.8-0.2-1.6-0.2-2.3c-0.8-0.6-1.6-0.4-2.3-0.2l-2.5,1.2c-3.9,2.1-7.8,4.3-11.1,7.4l-7.6,6.2
		c-3.1,2.5-6.2,5.1-9.2,8c-2.5,2.7-5.1,5.3-7.6,7.8c-2.9,2.9-5.7,6.2-9,8.8l-0.6,0.8c-5.9,6.1-11.7,12.1-17.6,18.3
		c-2.3,2.3-4.7,4.5-6.6,7c-0.2,0.4-0.6,0.8-1,1.2c-5.3,5.1-9.6,10.5-14.6,15.6c-2.5,2.3-4.9,5.1-7.2,7.8L126.5,137
		c-5.3,5.9-10.5,11.5-15.6,17.4c-9,10-17.8,20.1-26.7,30.3c-9.2,10.5-18.3,20.9-27.7,31.4c-6.2,7-12.7,13.9-19.1,20.7
		c-3.3,3.7-6.8,7.2-10.3,10.5c-2.7,2.7-5.9,5.3-8.8,7.6c-1.6,1.2-3.3,2.1-5.1,2.9c-2.7,1.4-5.3,1.4-7.6,0.2c-0.6-0.2-1.2-0.6-1.8-1
		c-2.7-1.6-3.9-3.9-3.7-7.2c0-2.5,0.2-4.9,0.6-7.4c1.2-6.6,2.7-13.1,5.1-19.5c1-3.1,2-6.4,3.1-9.8c1.2-3.9,2.5-7.8,4.1-11.7
		l5.3-13.7c3.1-8.4,6.8-16.8,10.3-25.2c3.3-7.4,6.4-15,10-22.4c2.7-6.4,5.9-12.7,8.6-19.1c4.3-9.4,8.8-18.7,12.3-28.3
		c0.6-1.6,1-2.9,1.6-4.3l0.6-2c0.2-0.6,0.4-1.2,1-1c0.4,0,0.8,0.4,1,0.8c0,1,0.2,2,0.2,3.1c-0.4,2.1-0.6,4.3-1.2,6.4
		c-0.8,2.9-1.8,5.9-2.7,8.6l-4.1,10.7c-2.3,5.9-4.7,11.5-7.2,17.4c-3.5,8.6-7.2,17-10.7,25.6c-2,4.9-3.7,9.8-5.7,14.6
		c-0.2,2.7-2,4.9-2.7,7.4l-7.6,20.5c-2.7,7.6-5.3,15.2-7.8,22.6c-1,2.7-1.6,5.7-2.3,8.4l-1.2,4.9c-0.2,0.4-0.4,1-0.4,1.4
		c-0.2,2.9-0.8,5.9-1.4,8.6c-0.2,1.6,0,3.1,0,4.5c0,0.4,0.4,0.6,0.6,1c0.8-0.4,1.6-0.6,2.1-1.2c2-1.6,3.9-3.1,5.9-4.9
		c2.5-2.5,5.1-5.1,7.6-7.8c2.9-2.7,5.7-5.7,8.4-8.6l17.4-19.1c3.9-4.3,7.8-8.6,11.5-12.9c4.7-5.3,9.2-10.3,13.7-15.6l7-7.8
		c1.2-1.2,2.1-2.5,3.1-3.7l15.4-17.4c3.1-3.5,6.6-7.2,9.8-10.7c2.3-2.5,4.5-5.1,6.6-7.6l12.9-14.1c4.5-4.9,8.8-10,13.3-14.8l7-7.4
		c3.3-3.3,6.4-6.8,9.6-10.3c4.7-5.3,9.8-10.1,14.6-15c2.1-2.3,4.5-4.7,6.6-6.8c1.2-1.4,2.5-2.7,3.9-4.1c3.5-3.5,7-7,10.7-10.5
		c4.5-4.5,9-8.8,13.7-12.9c3.9-3.3,7.8-6.4,12.1-8.8c1.8-1,3.5-1.8,5.5-2.3c2.9-1,5.9-0.8,8.2,0.6c1.6,0.8,3.1,1.8,4.5,2.9
		c3.3,2.5,4.3,6.2,4.3,10.5c-0.2,5.1-1,10-2.1,15c-0.6,2.9-1.4,5.9-2.1,8.8c-0.6,2.1-1.6,4.1-2.1,6.2c-0.6,2.3-1.4,4.5-2.5,6.6
		c-0.2,0.4-0.2,0.6-0.2,1c-0.2,2.5-1.6,4.7-2.5,7c-1.4,2.5-2.1,5.3-3.1,8c-1,2.3-2,4.9-2.7,7.2c-0.4,0.8-0.4,1.8-0.8,2.5l-1.2,3.5
		c-1.4,1.8-1.8,3.9-2.5,5.7c-1.4,3.1-2.5,6.2-3.7,9.4l-1,3.3c-0.6,1.4-1.4,2.7-2,4.1c-3.7,9-7.2,18-10.7,26.9
		c-1.6,3.7-3.3,7.2-3.9,11.1v0.4c0.2,0.2,0,0.2,0,0.4c0-0.2-0.2-0.2-0.2-0.4s0.2-0.4,0.2-0.6c-2,3.7-3.5,7.2-4.3,11.1v-0.2
		c-0.4,1-1.2,2-1.6,2.9l-5.3,14.1c-0.6,2.1-1.2,4.1-2,6.2h0.2c-0.6,1.2-1.4,2.5-1.8,3.7c-2,5.3-3.7,10.5-5.5,15.8
		c-0.6,1.2-0.8,2.5-0.4,3.5c-1,1.2-1.6,2.3-2,3.7c-1,2.9-2.1,6-2.9,9c-1.2,3.9-2.5,7.6-2.7,11.5v0.6c-2.1,4.5-2.1,9-2.5,13.7
		c-0.4,2.9-0.6,5.7-0.6,8.6c0.2,3.3,0.6,6.4,2,9.2c1.4,2.3,3.3,3.7,6.2,3.7c3.3,0,6.6-0.2,10.1-0.8c11.5-2,22.8-4.7,34.4-7.4
		c13.1-3.1,26.2-6.2,39.4-8.8l34.9-6.4c8.6-1.4,17.2-2.7,25.8-3.9c7.2-1.2,14.4-2.1,21.9-2.9c6.1-0.8,12.1-1.4,18.3-2
		c8-1,16-1.4,24-2c8.6-0.6,17-0.4,25.4-0.2c6.8,0.2,13.7,0.8,19.9,2.7c2.9,1,5.7,2.1,8.4,3.3c2.3,1.2,4.3,2.9,5.9,4.9
		C447.4,262.5,447.9,263.7,447.9,265.3z M33.6,165.7c0.6-1,1.2-2,1-2.9C34.2,163.8,33.8,164.7,33.6,165.7z M37.1,156.5h0.2v-0.2
		L37.1,156.5z M46.3,224.7h-0.2v0.2L46.3,224.7z M66.6,201.6c-0.4,0.6-0.8,1-1,1.6c-0.4,0.2-0.6,0.4-0.8,0.6L65,204
		c0.2-0.4,0.4-0.6,0.6-0.8l0.6-0.6C66.4,202.4,67,202.2,66.6,201.6z M66,196.7c0.2,0,0.2-0.2,0.4-0.2H66V196.7z M69.7,192.8
		c0-0.2-0.2-0.2-0.2-0.2c-0.2,0.2-0.4,0.4-0.6,0.8l-0.4,0.4v0.2c0,0,0.2,0,0.2-0.2c0,0,0.2-0.2,0.2-0.4L69.7,192.8z M71.8,189.9
		l0.2,0.2c0.2-0.2,0.4-0.6,0.6-0.8c0-0.2,0.2-0.2,0.4-0.4l0.2-0.2c-0.2,0-0.4,0-0.4,0.2c-0.2,0-0.2,0.2-0.2,0.4
		C72.2,189.5,72,189.7,71.8,189.9z M72.6,195.2c0,0-0.2,0.2-0.4,0.2c-0.2,0.2-0.2,0.2-0.2,0.4c0.2,0,0.4,0,0.4-0.2
		C72.4,195.6,72.6,195.4,72.6,195.2z M81.2,185.4c-0.6,0.6-1,1-1.4,1.6c-1,0.6-1.6,1.4-2.1,2.3l-1.4,1.8c-1,0.4-1.6,1.2-2,2.1
		c0.8-0.4,1.4-1.2,2-2.1c0.6-0.4,1.2-1,1.4-1.8c1-0.6,1.8-1.4,2.1-2.3C80.6,186.6,80.8,186.2,81.2,185.4z M77.1,184.3
		c0.2,0,0.2,0,0.2-0.2c0.2-0.2,0.2-0.2,0.2-0.4c-0.2,0.2-0.2,0.2-0.4,0.2V184.3z M77.9,183.1c-0.2,0.2-0.2,0.4-0.2,0.4
		c0.2,0,0.2,0,0.4-0.2c0,0,0-0.2,0.2-0.2H77.9z M85.7,180.7l-0.2-0.2c-0.2,0.2-0.4,0.6-0.6,0.8c-1.8,1-2.7,2.3-3.5,3.7
		c1.8-1,2.7-2.1,3.5-3.7C85.1,181.1,85.3,180.9,85.7,180.7z M81.8,178.8H82l0.2-0.2H82C81.8,178.6,81.8,178.8,81.8,178.8z
		 M87.3,172.9h-0.4c0,0,0,0,0,0.2C87.1,173.1,87.1,172.9,87.3,172.9z M97,167.7c-0.8,0.4-1.2,0.8-1.4,1.6c-1,0.4-1.6,1.4-2.1,2.3
		c-1.6,1-2.7,2.3-3.5,3.9l-1.6,1.8c-0.2,0.2-0.4,0.2-0.4,0.4c-0.2,0-0.2,0.2-0.2,0.4c0.2,0,0.4,0,0.4-0.2c0.2-0.2,0.2-0.4,0.2-0.6
		c0.8-0.4,1.2-1,1.6-1.8c1.6-1,2.7-2.3,3.5-3.9c1-0.6,1.8-1.4,2.1-2.3C96.4,168.8,96.8,168.3,97,167.7z M89.4,170.6
		c0-0.2,0-0.2,0.2-0.2c0-0.2,0.2-0.4,0.2-0.6c0.8-0.4,1.2-1,1.6-1.8c0,0,0.2-0.2,0.4-0.2c0.2-0.2,0.2-0.4,0.2-0.6
		c-0.2,0.2-0.4,0.2-0.4,0.2c-0.2,0.2-0.2,0.4-0.2,0.6c-0.8,0.4-1.4,1-1.6,1.8c-0.2,0.2-0.4,0.2-0.4,0.4
		C89.2,170.2,89.4,170.4,89.4,170.6z M94.3,164.7c0.2,0,0.4-0.2,0.4-0.2c0-0.2,0.2-0.4,0.2-0.6c0.2,0,0.4,0,0.6-0.2
		c0-0.2,0-0.4,0.2-0.4c-0.2,0-0.4,0-0.6,0.2c0,0-0.2,0.2-0.2,0.4c-0.2,0.2-0.4,0.2-0.4,0.4C94.3,164.3,94.3,164.5,94.3,164.7z
		 M97.8,166.7c-0.2,0.2-0.4,0.2-0.4,0.2c-0.2,0.2-0.2,0.4-0.2,0.6c0.2-0.2,0.2-0.2,0.4-0.2C97.6,167.1,97.6,166.9,97.8,166.7z
		 M98,160.8c0.2-0.4,0.4-0.6,0.4-0.8l-0.6,0.6C97.8,160.6,98,160.6,98,160.8z M99.2,159.3c-0.2,0-0.4,0-0.4,0.2
		c-0.2,0.2-0.2,0.2-0.2,0.4c0,0,0.2,0,0.4-0.2C99,159.7,99.2,159.5,99.2,159.3z M111.3,151.9c0.6-0.6,1-1.2,1.4-2l-2.3,2.1
		c-3.1,3.5-6.4,7.2-9.8,10.9c-0.2,0.4-0.4,0.8-1,1.6c0.8-0.4,1-0.6,1.2-0.8C104.2,159.9,107.8,156,111.3,151.9z M101.3,156.7
		c0.2,0,0.4-0.2,0.6-0.2c0-0.2,0-0.4,0.2-0.6c0.8-0.4,1.2-1,1.4-1.8c1-0.4,1.6-1.2,2-2.1c-0.8,0.4-1.4,1.2-2,2.1
		c-0.6,0.4-1.2,1-1.4,1.8l-0.6,0.4C101.5,156.3,101.3,156.5,101.3,156.7z M106,151.1c0,0.2,0,0.4-0.2,0.6l0.6-0.4
		C106.2,151.3,106.2,151.3,106,151.1z M108.5,148.7c0.2,0,0.2,0,0.4-0.2c0-0.2,0.2-0.4,0.2-0.6l-0.6,0.4V148.7z M109.5,147.2
		c0,0.2-0.2,0.4-0.2,0.6c0.2,0,0.4-0.2,0.6-0.4c0,0,0-0.2-0.2-0.4C109.7,147.2,109.5,147.2,109.5,147.2z M112.1,144.6
		c0.2,0,0.4-0.2,0.6-0.2c0-0.2,0-0.4,0.2-0.6l0.6-0.4c0.2,0,0-0.4,0-0.4s-0.2,0-0.2,0.2l-0.4,0.6c-0.2,0.2-0.4,0.2-0.6,0.4
		C112.2,144.2,112.2,144.4,112.1,144.6z M119.9,141.7c-1.4,1-2.5,2.3-3.5,3.9c-1.4,1-2.5,2.3-3.3,3.9c1.6-1,2.5-2.3,3.5-3.9
		C118.1,144.6,119.1,143.5,119.9,141.7z M115.6,140.3c0,0.2,0.2,0.4,0.2,0.4h0.2c0.2-0.2,0.4-0.6,0.6-0.8l0.6-0.6c0,0,0-0.2,0-0.4
		h-0.4c-0.2,0.4-0.2,0.6-0.4,0.8L115.6,140.3z M119.1,136.8c0.2,0,0.2,0,0.4-0.2h-0.4V136.8z M218,40.8c-2.1,1.6-4.1,3.1-6.1,4.7
		c-5.3,4.7-10.5,9.6-15.4,14.6c-9.8,9.8-19.3,19.7-29.1,29.5l-6.2,7c-0.8,0.6-1.4,1.4-2.1,2.1l-4.9,5.3c-1.4,1.6-2.9,3.1-4.3,4.7
		c-0.6,0.4-1.2,0.8-1.6,1.4c-5.7,6.1-11.1,12.1-16.8,18.3c-0.6,0.6-1,1.4-1.6,2.1c-1,0.6-1.8,1.2-2.1,2.1l-3.7,4.1
		c-0.6,0.8-1.4,1.6-2.1,2.3c-0.8,0.6-1.4,1.2-2,2.3c1-0.4,1.8-1.2,2.3-2.1l2.1-2.3c1.2-1.2,2.3-2.5,3.5-3.9c1-0.6,1.6-1.4,2-2.5
		c0.8-0.6,1.8-1.2,2.3-2c3.9-4.1,7.6-8.4,11.5-12.5c2.1-2.3,4.1-4.9,6.2-7.2c1.8-1.4,3.1-2.9,4.3-4.7c1.8-1.6,3.3-3.3,5.1-5.1
		c0.6-0.8,1.4-1.6,2.1-2.3c0.8-0.6,1.6-1.4,2.1-2.1L191.9,66c8-8.2,16.2-16.2,25-23.6C217.3,42,217.8,41.8,218,40.8z M121.4,134.3
		c0.2,0,0.2,0,0.2,0l0.6-0.6c0.2-0.2,0.4-0.4,0.6-0.6s0-0.4,0-0.6l-0.2,0.2c-0.2,0.2-0.4,0.4-0.6,0.8c-0.2,0-0.4,0.2-0.6,0.4V134.3z
		 M124.9,130.4c1-0.6,1.8-1.2,2.5-1.8l1.2-1.4c0.6-0.8,1.4-1.6,2-2.3c0.4-0.4,0.6-1.2,1-1.8c1-0.4,1.8-1,2.1-2.1
		c1-0.6,1.6-1.4,2-2.5c1-0.4,1.8-1.2,2.3-2.1l0.6-0.6c0,0,0-0.2-0.2-0.4h-0.2c-0.2,0.2-0.4,0.6-0.6,0.8c-0.8,0.6-1.6,1.4-2,2.3
		c-1,0.6-1.8,1.4-2.1,2.3c-0.8,0.8-1.6,1.6-2.1,2.3c-1.6,1-2.5,2.5-3.9,3.9C126.5,128,125.5,129,124.9,130.4z M140.7,113.2
		c0.2,0,0.2-0.2,0.2-0.2c0.2-0.2,0.4-0.4,0.6-0.8c1-0.4,1.6-1.2,2.1-2.3c0.8-0.4,1.6-1.2,2-2.1c0.6-0.4,1.2-0.6,1.6-1
		c0.2,0,0.2-0.4,0-0.4c0-0.2-0.4-0.2-0.4-0.2c-0.4,0.6-0.8,1-1.2,1.4l-2.1,2.3c-1,0.6-1.6,1.4-2.1,2.3l-0.6,0.6
		C140.5,112.8,140.7,113,140.7,113.2z M148.9,103.8c0,0,0,0.2,0.2,0.2c0,0,0.2-0.2,0.4-0.2l-0.2-0.2
		C149.1,103.6,149.1,103.8,148.9,103.8z M151.3,101.3c0,0,0.2,0.2,0.2,0.4c0,0,0.2,0,0.2-0.2l0.6-0.6c1.2-0.8,2-1.8,2.7-2.9l2.1-2.3
		c1.4-0.2,1.8-1.2,2.1-2.3c2.1-1.2,3.7-2.9,4.9-5.1c1-0.6,1.8-1.2,2.1-2.3c1.4-0.6,2.3-1.4,2.9-2.9c1-0.4,1.8-1.2,2-2.1
		c1.2-1,2.3-1.6,2.9-2.9c1.4-0.6,2.3-1.6,2.7-2.9c1.8-0.4,2.3-1.6,2.9-2.9c1.6-0.2,2.1-1.6,2.7-2.7c0.8-0.2,1.4-0.6,1.8-1.2
		c3.7-3.5,7.4-7,10.9-10.5c1.4-1.4,2.5-2.7,3.3-4.3c0.6-0.4,1.2-0.6,1.6-0.8c1.4-1.2,2.5-2.5,3.3-3.9c1-0.2,1.6-0.8,2.1-1.4
		c1.2-1.2,2.3-2.3,3.3-3.5c0.4-0.4,0.8-0.6,0.2-1c-2.1,1.8-4.1,3.3-6.1,5.5c-1.6,1.6-3.1,3.1-4.7,4.5c-5.5,4.9-10.5,10.1-15.6,15.4
		l-0.6,0.8c-0.8,1-1.8,2-2.7,2.9c-1,1-2,2-2.7,2.9c-1,0.8-2,1.8-2.9,2.7c-0.8,1-1.8,2-2.7,2.9l-2.1,2.3c-1,1-2,2-2.7,2.9l-2.1,2.1
		c-2,1.6-3.5,3.3-4.9,5.3c-1,0.6-1.8,1.4-2.1,2.3l-2.1,2.1c-1,1.2-2,2.1-2.9,3.1L151.3,101.3z M177.4,235v-0.2l0.2-0.2v0.2
		C177.6,234.8,177.6,235,177.4,235z M181.3,223.9l-0.6,1.2c0.2-0.4,0.2-0.8,0.4-1.4C181.1,223.9,181.1,223.9,181.3,223.9z
		 M186,210.2l-0.2-0.2h0.2V210.2z M187,206.7c0,0,0,0,0-0.2h0.2c0,0,0,0,0,0.2H187z M188.4,202.4c0-0.2,0-0.2,0-0.4h0.6
		c-0.2,0.2-0.2,0.2-0.4,0.4c0,0.2-0.2,0.2-0.2,0.4V202.4z M225.1,108.5v-1c0.2,0.4,0.2,0.6,0.2,0.8
		C225.3,108.5,225.1,108.5,225.1,108.5z M226.2,104.4V104h0.2C226.2,104.2,226.2,104.4,226.2,104.4z"
      />
      <path
        id="XMLID_47_"
        d="M290.2,172l-3.5,3.3c-0.4,0.4-0.8,0.8-1.6,1v-0.4c0,0.2-0.2,0.4-0.4,0.4l-0.4,0.6c-0.2,0-0.4,0.2-0.4,0.4
		c-0.2,0-0.4,0.2-0.4,0.4l-1.2,0.6c-2.7,2.9-5.7,5.7-8.6,8.6c-0.2,0.2-0.4,0.6-0.6,1c-0.2,0-0.4,0.2-0.4,0.4
		c-0.2,0.2-0.4,0.2-0.4,0.4c0.2,0,0.2-0.2,0.4-0.4c0.2,0,0.2-0.2,0.4-0.4c0.8-0.2,1.2-1,1.8-1.4c0.6-0.8,1.2-1.6,2.1-2
		c-0.2,0.8-0.8,1.2-1.4,1.6c-3.1,3.5-6.4,7-9.8,10.5c-2,2.3-3.9,4.5-6.1,6.6c-2.9,3.5-6.2,6.6-9.6,9.6c-0.8,0.8-1.8,1.4-2.7,2
		c-1.8,0.8-3.5,0.8-5.1-0.6l-1-0.6c-1.2-1-1.8-2.1-1.8-3.9c0-1.6,0.2-2.9,0.6-4.5c1.4-6.1,3.7-11.9,5.9-17.8
		c0.6-2.1,1.4-4.1,2.1-6.2c0.2-0.4,0.4-1,0.4-1.4c0.2-0.4,0-0.6-0.2-0.8c-0.8-0.2-0.8-0.8-0.8-1.6v-2.3c0-0.6-0.6-0.6-1-0.6v0.2
		c-0.2,0-0.2,0.2-0.4,0.4v-0.4c-0.2,0-0.4,0.2-0.4,0.4h-0.2c-0.2,0.4-0.4,0.8-0.8,1.2c0,0.2-0.2,0.4-0.4,0.4l-0.4,0.6
		c0.2,0,0.2,0,0.4,0c-0.2,0-0.2,0.2-0.4,0.4c-4.5,5.5-9.8,10.1-16,13.7c-1.4,0.8-2.9,1.4-4.5,1.8c-1.2,0.2-2.1,0.2-3.3-0.2
		c-2.3-0.8-3.7-2.5-4.7-4.7c-0.4-0.6-0.4-1.4-0.2-2.1c0.2-1.8,0.6-3.1,1.2-4.5c0.8-1.6,1.6-2.9,2.3-4.1c2.3-3.1,5.1-6.1,8.2-8.2
		c3.5-2.3,7-3.7,11.3-3.5c1.2,0,2.3,0.4,3.5,0.8c1.2,0.4,1.4,0.4,2.3-0.8c1.4-2.1,2.9-4.1,4.3-6.2l0.8,0.4v-0.2
		c3.1-3.3,5.9-7.2,9.2-10.3c0.6-0.8,1.4-1.6,2.1-2.1c1-0.8,1.6-0.8,2.5-0.2c0.6,0.4,1,0.6,1.4,1c1.8,1.2,2.1,2.5,1.6,4.5
		c-1,2.7-1.8,5.5-3.1,7.8c-0.4,0.6-0.6,1-0.8,1.6l0.6-0.8c0,0.6-0.2,1.2-0.8,1.8V162c-0.2,1-0.6,1.8-1,2.7l-0.2,0.8l1,0.6
		c0,0.2-0.2,0.6-0.4,0.8c-0.2,0.6-0.2,1-0.4,1.4c-0.8,1-1.6,2.3-1.6,3.7c-0.8,1-1.4,2-1.4,3.1c-0.2,0.2-0.4,0.4-0.4,0.8v-0.2
		c-0.6,1-1,2-1.4,3.1c-0.4,0.6-0.8,1.4-0.8,2.1c-0.4,0.4-0.4,0.8-0.6,1.4h0.2c-0.6,0.6-0.8,1.4-1,2.1c-0.2,0.2-0.2,0.6-0.4,0.8
		c-0.6,0.4-0.8,1-1,1.6l-2.9,8c-0.4,0.8-0.8,1.8-0.8,2.7c-1.4,2.5-2.1,5.1-2.5,7.8c-0.6,1-0.8,2.1-0.8,3.3h-0.2c1,0.8,1,0.8,1.4,0.4
		l1.8-1.4c3.1-3.3,6.4-6.8,9.8-10.1c2.9-2.9,5.9-5.9,8.8-8.6c3.1-3.3,6.4-6.4,9.8-9.6c3.1-2.9,6.1-6.1,9.4-8.8
		c0.4-0.2,0.6-0.4,0.8-0.6c1.2-1,1.4-1,2.5,0C291,170.8,291,171.2,290.2,172z M236.2,176.4l3.7-4.7c0.6-0.6,1-1.4,1.6-2.1
		c0.2-0.2,0-0.6-0.4-0.8h-1c-1.6,0-3.3,0.4-4.9,1.2c-1.8,0.8-3.5,2-5.1,3.1c-0.6,0.4-1.2,1-2,1c0.4-0.8,1.4-1.2,1.8-2
		c0.6-0.2,1-0.4,1.2-1c-0.6,0.2-1,0.4-1.4,0.8c-2.1,1.4-4.1,3.1-5.5,5.7c-0.6,0.2-1,1-1.2,1.6c0.6-0.2,1-0.8,1.2-1.6
		c0.8-0.4,1.2-1,1.6-1.4c0.6-0.6,1.2-1.4,2-1.8c0,0.8-0.6,1.4-1,2c-1.8,1.4-2.9,3.1-4.1,5.1c-1.2,1.8-1.6,3.9-2,5.9
		c-0.2,0.8,0.4,1.2,1.2,1c1.2-0.6,2.5-1.2,3.7-2.1C229.5,183.3,233.1,180,236.2,176.4z M222.1,180.4c0.6-0.2,0.8-0.6,1-1
		C222.7,179.6,222.5,180,222.1,180.4z M222.7,175.5h0.4c0-0.2,0-0.2,0-0.4h0.4c0,0.2,0,0.2,0,0.4l0.4-0.4c0.2-0.2,0.2-0.4,0.4-0.4
		h-0.4c0,0,0,0.2,0,0.4h-0.4c0-0.2,0-0.4,0-0.4c-0.2,0-0.2,0.2-0.4,0.4C222.9,175.1,222.7,175.3,222.7,175.5z M223.5,174.5h0.4v-0.2
		C223.7,174.3,223.7,174.5,223.5,174.5z M223.9,174.1h0.4c0,0.2,0,0.4,0,0.4c0.2,0,0.4-0.2,0.4-0.4h-0.4c0,0,0-0.2,0-0.4
		C224.3,173.9,224.1,174.1,223.9,174.1z M224.5,173.7h0.2c0-0.2,0-0.2,0-0.4C224.7,173.5,224.5,173.5,224.5,173.7z M224.9,174.1
		c0,0,0.2-0.2,0.4-0.4c1-0.8,2.1-1.6,2.5-2.9c0.2,0,0.2-0.2,0.4-0.4c0.4,0,0.6-0.2,0.8-0.4c-0.2,0-0.6,0-0.8,0.4l-0.6,0.4
		c-1.2,0.4-2.1,1.4-2.7,2.5c0,0.2,0.2,0.2,0.4,0.4h-0.4V174.1z M235,181.5v0.2c0.2,0,0.2-0.2,0.4-0.2H235c0-0.2,0.2-0.4,0.4-0.6v0.4
		c0.2,0,0.2-0.2,0.4-0.4s0.4-0.2,0.4-0.4h-0.4c0.2-0.2,0.4-0.2,0.4-0.4c0,0.2,0,0.2,0.2,0.4c0-0.2,0.2-0.2,0.2-0.4h-0.4
		c0.2-0.2,0.4-0.4,0.6-0.4v0.4c0-0.2,0.2-0.4,0.4-0.4c0.8-0.6,1.4-1.2,1.6-2.1h-0.2h-0.2c0.2-0.2,0.4-0.4,0.6-0.4v0.4
		c0.2,0,0.2-0.2,0.4-0.4l0.4-0.4c-0.2,0-0.4,0-0.4,0c0-0.2,0.2-0.4,0.4-0.6v0.4c0.2,0,0.2-0.2,0.4-0.4c0.4-0.2,0.8-0.8,0.8-1.2
		l0.4-0.4c0,0-0.2,0-0.4,0v0.4c0,0-0.2,0-0.4,0c0.2-0.2,0.4-0.4,0.4-0.6c0.2,0,0.4-0.2,0.6-0.4v0.4c0,0,0.2-0.2,0.4-0.4h-0.4
		c0-0.2,0-0.4,0-0.8c0.2,0,0.2-0.2,0.4-0.4h0.4c0,0.2,0,0.6,0,0.8c0.4-0.4,0.8-0.8,0.8-1.2h-0.4c0.2-0.2,0.2-0.4,0.4-0.4v0.2
		c0.2,0,0.4-0.2,0.6-0.2H243c0-0.4,0-0.6,0-1c-0.6,0.4-1,1-1.2,1.8c-0.2,0-0.4,0.2-0.6,0.4c-0.6,0.4-1,1-1.4,1.6
		c0,0.2-0.2,0.4-0.4,0.4c-1,1-1.8,2-2.5,2.9c-1.4,1.6-2.9,2.7-4.1,4.5c0.6,0,1.2-0.6,1.8-0.8C234.6,181.7,234.8,181.5,235,181.5z
		 M234.8,185.4c-0.4,0-0.8,0.2-1,0.6C234.2,186,234.6,185.6,234.8,185.4z M246.5,172.9c0.2-0.2,0.2-0.4,0-0.6c0,0-0.4,0-0.4,0.2
		l-0.8,0.8c-2.3,3.1-5.1,6.1-7.8,9l-1,1.2c0,0.2-0.2,0.2-0.4,0.4c-0.4,0.2-0.6,0.4-0.8,1c0.4-0.2,0.8-0.4,1-0.8l0.4-0.4
		c0.8-0.4,1.2-0.8,1.8-1.4c2.7-2.7,5.3-5.9,7.8-9C246.5,173.1,246.5,173.1,246.5,172.9z M235.8,180.5v0.4c-0.2,0-0.2,0-0.4,0
		C235.6,180.9,235.6,180.7,235.8,180.5z M239.3,176.8v0.4c-0.2,0-0.4,0-0.4,0C238.9,177,239.1,176.8,239.3,176.8z M240.1,175.5
		c0,0.2,0,0.6,0,0.8h-0.4c0-0.2,0-0.4,0.2-0.6L240.1,175.5z M240.3,175.1h0.2c0,0.2-0.2,0.2-0.4,0.4
		C240.1,175.3,240.1,175.3,240.3,175.1z M243.6,170.8h0.4c0-0.2,0.2-0.4,0.4-0.4C243.8,170,243.6,170,243.6,170.8z M244.8,176.8
		c0.2,0,0.4,0,0.4,0s-0.2,0.2-0.4,0.2V176.8z M250.4,167.5c1-1.2,2-2.3,2.7-3.7H253l-0.6,0.6c0.2-0.2,0.2-0.2,0.4-0.2
		c-0.2,0.4-0.6,0.6-1,0.8l-0.4,0.6c0,0,0,0.2-0.2,0.4v-0.2l-0.6,0.6c-0.2,0.4-0.2,0.8-0.4,1l-0.8,0.8c0,0.2-0.2,0.2-0.2,0.2
		c-0.4,0.4-0.6,1-1,1.4v0.4c0.2,0.2,0.4,0,0.4,0C249.4,169.2,250,168.4,250.4,167.5z M248.7,195.8c0.4-0.6,0.8-1.2,0.8-2
		C248.9,194.4,248.9,195,248.7,195.8z M254.1,164l-4.5,6.2c-0.6,1-0.6,2-0.2,3.3c0.2,0.8,0.2,1.6,0.4,2.3c0,0.2-0.2,0.6,0.4,0.6
		c0.2-0.2,0.4-0.4,0.4-0.6C251.2,174.7,255.3,164.7,254.1,164z M251.6,188v0.2C251.8,188.2,251.8,188,251.6,188z M253,163.8
		c0,0.2-0.2,0.2-0.2,0.4C252.8,164,252.8,164,253,163.8z M253.5,157.9c0.4-0.2,1-0.4,1.2-1l0.4-0.6c0.6,0,1-0.4,1.2-0.8
		c0-0.2,0.2-0.4,0.4-0.6v0.4c0.2-0.2,0.2-0.4,0.4-0.6c0.6-0.2,1-0.6,1.2-1.2l0.4-0.6c-0.2,0-0.4,0.2-0.4,0.2
		c-0.2-0.2-0.2-0.4-0.2-0.6h0.4c0-0.2,0-0.4,0-0.4c-0.2,0-0.2,0.2-0.4,0.4c-0.8,0.4-1.4,1-1.8,1.8c-0.6,0.4-1.2,1-1.4,1.8
		c-0.2,0.2-0.4,0.4-0.4,0.6L253.5,157.9z M255.9,177.6c-0.6,0.6-0.8,1.2-1,2C255.5,179,255.7,178.4,255.9,177.6z M256.7,154.6
		c0.2,0.2,0.2,0.2,0.4,0.2c-0.2,0-0.2,0-0.4,0.2V154.6z M256.7,179v-0.2h0.2C256.7,178.8,256.7,179,256.7,179z M258,172.5
		c-0.4,0.4-0.4,0.8-0.4,1.2C257.8,173.3,257.8,172.9,258,172.5z M258.4,152.1c0.6-0.2,1-0.6,1.2-1.2c0.2,0,0.4-0.2,0.4-0.2l0.2-0.2
		c-0.2-0.2-0.2-0.2-0.4,0c0,0.2-0.2,0.2-0.2,0.4C259,151.3,258.8,151.7,258.4,152.1z M259.6,168.8c0,0.2-0.2,0.2-0.4,0.4
		c0,0.2,0,0.4,0.2,0.4l0.2-0.2V168.8z M264.7,193.4c-1.8,1.4-3.1,3.1-4.7,4.9c-0.4,0.2-0.4,0.6-0.6,1c1.8-1.4,3.3-3.3,4.9-5.1
		C264.5,194,264.7,193.8,264.7,193.4z M263.9,197.7c-0.2,0-0.2,0.2-0.4,0.4c-1.6,1-2.9,2.7-3.7,4.1c1.6-1.2,2.7-2.5,3.7-4.1
		C263.7,197.9,263.9,197.9,263.9,197.7z M261,165.7c-0.6,0.6-0.8,1.2-0.8,2C260.6,167.1,261,166.5,261,165.7z M262.3,166.9
		c-0.2-0.2-0.2-0.6-0.2-0.8h0.2V166.9z M264.3,197.1l-0.4,0.4C264.1,197.5,264.3,197.3,264.3,197.1z M264.7,196.7
		c-0.2,0.2-0.2,0.2-0.2,0.4C264.5,196.9,264.7,196.9,264.7,196.7z M272.1,189.1c-0.4,0-0.4,0.2-0.6,0.4l-0.4,0.4
		c-0.2,0-0.2,0.2-0.4,0.4c-0.2,0.2-0.4,0.2-0.4,0.4l-0.4,0.4c-2,1.6-3.5,3.3-4.9,5.1c-0.2,0.2-0.2,0.4-0.2,0.6
		c2-1.6,3.7-3.3,5.1-5.5c0.2-0.2,0.4-0.2,0.4-0.4l0.4-0.4c0.2-0.2,0.4-0.4,0.6-0.6l0.4-0.4C271.9,189.5,271.9,189.3,272.1,189.1z
		 M267,191.1c0.2,0,0.2,0,0.2-0.2L267,191.1z M267.8,190.3c1.4-0.6,2.3-1.6,2.9-2.9C269.5,188.2,268.6,189.1,267.8,190.3z
		 M275.4,182.7c-1.4,1.2-2.7,2.3-3.7,3.7C273.3,185.6,274.6,184.4,275.4,182.7z M277.2,184.4c0-0.2,0.2-0.4,0.4-0.6v0.2
		C277.4,184.1,277.4,184.3,277.2,184.4z M277.6,183.9c1.2-1.4,2.3-2.5,3.7-3.7C280.5,181.7,279.3,183.1,277.6,183.9z M282.2,176.4
		c-1.8,0.8-2.9,2.1-4.1,3.5c0.8-0.2,1.4-0.6,2-1.2L282.2,176.4z M281.8,179.8c-0.2,0.2-0.4,0.2-0.4,0.4
		C281.5,180,281.7,179.8,281.8,179.8z M283.6,177.6c0,0,0.2,0,0.4,0c-0.6,1-1.2,1.6-2.1,2L283.6,177.6z M284,177.6v-0.4
		c0,0,0.2,0,0.4,0C284.2,177.2,284,177.4,284,177.6z M284.4,177v-0.2c0.2,0,0.2,0,0.4,0C284.6,176.8,284.6,177,284.4,177z
		 M284.8,176.3c0.2,0,0.2,0,0.4,0c-0.2,0.2-0.2,0.4-0.4,0.4V176.3z M287.9,170.8c-1,0.6-1.6,1.4-2.1,2
		C287.1,172.2,287.1,172.2,287.9,170.8z"
      />
      <path
        id="XMLID_96_"
        d="M376.5,2.9c-0.8,3.1-1.4,6.4-2.3,9.8c-0.2,1.2-0.4,2.3-0.8,3.5c-0.4,1.8-1,3.5-1.4,5.3l-1.8,5.3
		c-0.8,2.1-1.4,4.3-2.1,6.4c-0.8,2-1.6,3.7-2.1,5.7l-1.8,4.3c-0.8,1.8-1.6,3.7-2.3,5.7c-1,2.1-1.8,4.3-2.7,6.4l-1.8,4.3
		c-2.3,4.7-4.5,9.4-6.6,14.1c-0.8,1.8-1.8,3.5-2.7,5.5c-0.8,1.6-1.6,3.3-2.5,5.1c-1.8,3.5-3.5,7.2-5.5,10.9
		c-2.1,3.9-4.3,7.8-6.4,11.9l-4.9,9c-1,1.8-2.1,3.7-3.1,5.5l-2.7,4.7c-1,1.8-2,3.3-2.9,5.1c-0.4,0.8-1,1.6-1.6,2.3
		c-0.6,1.2-1.2,2.3-2,3.3l-2.9,5.3c-1.4,2-2.5,4.1-3.7,6.1s-2.3,4.1-3.7,6.1c-0.6,1-1.4,2.1-2,3.3l-1.8,2.9c-0.8,1-1.4,2-2,2.9
		c-0.4,0.8-0.8,1.6-1.4,2.3l-1.8,2.7c-0.4,0.8-0.8,1.4-1.2,2c-0.6,1-1,2-1.6,2.7l-2.5,3.7c-0.2,0.4-0.2,0.6-0.4,1c0,0,0,0.2,0.2,0.2
		h0.2c0.4-0.4,0.8-0.6,1-1c0.4-0.4,0.8-0.8,1.2-1c0.4-0.2,0.8-0.6,1-0.8c2.1-2.7,4.7-4.7,7.2-6.8c2.3-1.6,4.5-2.9,6.8-4.3
		c1.8-0.8,3.5-1.6,5.3-1.8c1.4-0.2,2.5-0.4,3.9-0.4c1.8,0.2,3.3,1,4.7,2.3c1.4,1.6,2.5,3.3,3.1,5.5c0.6,1.6,0.8,2.9,0.6,4.7
		c-0.2,2-0.6,3.7-1.2,5.5c-0.6,1.8-1.4,3.5-2.1,5.1c-1,1.6-1.8,2.9-2.9,4.3l-1.4,1.8c-2.3,2.9-5.3,4.7-8.2,6c-1.2,0.6-2.3,1-3.5,1
		c-0.4,0.2-0.8,0.2-1.2,0.4c-2,0.4-3.9,0-5.9-0.6c-2-0.6-3.7-1.6-5.3-3.3c-1.2-1.2-2.1-2.5-3.1-4.1c0-0.2-0.2-0.4-0.2-0.6
		c-0.4-0.4-0.6-0.6-1.2-0.2l-0.6,0.6c-1.2,1.2-2.3,2.5-3.3,3.7c-1.6,2-3.1,3.7-4.7,5.5c-1.4,1.6-2.7,2.9-3.9,4.7
		c-1.4,1.8-2.7,3.3-4.3,4.9l-1.8,2.1c-1.2,1-2,1-2.9-0.2c-0.4-0.4-0.8-1-1.2-1.6c-0.2-0.2-0.4-0.6-0.6-1c-0.4-0.8-0.6-1.6-0.8-2.3
		c-0.4-0.8-0.2-1.8,0.4-2.5c0.6-0.8,1-1.8,1.6-2.7c0.2-0.6,0.6-1.2,0.8-1.8c0-0.4,0.2-0.6,0.4-0.8c0.2-0.4,0.4-0.8,0.8-1.2
		c0.4-0.6,0.8-1.4,1.2-2.1c0.2-0.4,0.4-0.8,0.6-1l2.1-3.5c0.6-1.2,1.4-2.1,2.1-3.3c0.2,0,0.2-0.2,0.4-0.2c1-0.8,1.6-1.8,2.1-2.9
		c0,0-0.2,0-0.2-0.2l-0.6,0.8c-0.2,0.2-0.2,0.6-0.6,0.8v-0.2c0.6-0.8,1-1.8,1.6-2.3c0.6-0.8,1-1.6,1.4-2.3c0.8-1.2,1.4-2.3,2.3-3.5
		c0.2-0.2,0.4-0.6,0.6-1c0.6-1.6,1.8-2.9,2.5-4.1c0.2-0.2,0.2-0.4,0.4-0.4c1-0.8,1.8-1.8,2.3-2.9c0-0.2,0.2-0.4,0.2-0.6
		c-0.2,0-0.2,0.2-0.4,0.4c0,0-0.2,0.2-0.2,0.4c-0.2,0.2-0.2,0.4-0.4,0.4c-0.2,0.2-0.2,0.4-0.4,0.2c0-0.2,0-0.2,0.2-0.4l1-1.6
		c0.6-0.8,1-1.6,1.6-2.3c0.4-1,1-1.8,1.8-2.3c0.8-1,1.6-2,2.1-3.1v-0.2c-0.4,0.2-0.6,0.6-0.8,0.8c-0.2-0.2-0.2-0.4,0-0.4
		c0.4-0.8,1-1.8,1.8-2.1c0.8-0.6,1.4-1.6,1.6-2.5l-0.8,1.2c-0.2,0-0.2,0.4-0.6,0.2c0,0,0-0.2,0.2-0.2c0.2-0.4,0.4-0.8,0.8-1.2
		l1.2-2.1c0-0.2,0.2-0.4,0.4-0.6l1.2-1.8c0.4-0.6,0.6-1,0.8-1.6l1.2-1.8c0.4-0.6,0.8-1.2,1-1.8l0.6-1.2c0.8-1,1.4-2.1,2-3.1l2-2.9
		c0.2-0.2,0.4-0.4,0.6-0.4c0.6-0.6,1-1.2,1.4-2.1c-0.4,0.2-0.6,1-1,0.8c0.4-0.6,0.6-1.2,1.2-1.4c0.4-0.2,0.8-0.6,1-1.2
		c0.4-1,1-1.8,1.6-2.5c0.4-0.8,0.8-1.4,1-2.1c-0.2,0.4-0.6,0.6-0.8,1c0,0.2-0.2,0.2-0.4,0.2c0-0.2,0.2-0.4,0.2-0.4l0.8-1.4
		c0.4-0.6,1-1.2,1.2-2l0.6-1c0.4-0.6,1-1.2,1.2-2c0-0.2,0.2-0.4,0.4-0.8l1-1.2c0.2-0.4,0.4-0.8,0.6-1.4c0.2-0.4,0.4-0.8,0.8-1.2
		c0.2-0.2,0.4-0.6,0.6-1c0.2-0.8,0.6-1.4,1.2-2.1c0.2-0.4,0.6-0.8,0.8-1.4c0.4-0.8,0.8-1.4,1.4-2l1-1.2c-0.2-0.2-0.2-0.2-0.4-0.2
		c0-0.4,0.2-0.6,0.4-0.8c0.4-0.6,0.6-1,0.8-1.6l0.4-0.6c0.6-1.2,1.4-2.1,2-3.3c0.4-1,1-1.8,1.4-2.9c0-0.2,0.2-0.4,0.4-0.6
		c0.6-1.2,1.2-2.1,1.8-3.1c0.2-0.2,0.4-0.6,0.4-0.8l0.6-1.2c0.2-0.2,0.4-0.4,0.6-0.8c0.2-0.4,0.4-1,0.8-1.4c0.2-0.2,0.2-0.6,0.4-0.8
		c0-0.4,0.2-0.8,0.4-1c0.2,0,0.4,0.2,0.4,0.2c0.4-0.4,0.4-0.6,0.6-1s0-0.6,0-1c0.4-1,1-1.8,1.4-2.7c0,0,0.2,0,0.2-0.2
		c0.2-0.6,0.6-1.4,1.2-1.8c0.2-0.2,0.4-0.4,0.4-0.6v-0.8c0.2-0.6,0.6-1.2,1-1.8c0-0.2,0.2-0.4,0.4-0.4c0.6-0.4,0.8-1,0.8-1.6
		c0,0.2-0.2,0.4-0.4,0.6v-0.4c0.2-0.2,0.2-0.4,0.4-0.6c0.4-0.2,0.6-0.4,0.6-0.8c0-0.6,0.2-1,0.6-1.2c0.2-0.4,0.4-0.6,0.4-1
		c0.4-1.2,1-2.1,1.4-3.1l0.6-0.6c0.2-0.2,0.4-0.4,0.4-0.6V73c0.4-0.2,0.8-0.8,0.8-1.4c0.2-1,0.8-2,1.2-2.9l0.6-1.2
		c0.2-0.4,0.4-0.6,0.4-0.8c0.8-1.4,1.4-2.7,2-4.1l0.6-0.6c0.2-0.4,0.4-0.6,0.4-1.2c0,0,0-0.2,0.2-0.4c0.4-1,0.8-2,1.4-3.1
		c0.2-0.2,0.2-0.4,0.4-0.8l0.4-0.6c0.2-0.2,0.4-0.4,0.4-0.8c0-0.6,0.2-1,0.4-1.4c0.4-0.8,0.8-1.6,1-2.3l1-2.1
		c0.6-1.2,1.2-2.3,1.6-3.5c0.2-0.6,0.4-1.2,0.8-1.6c0-0.2,0.2-0.4,0.2-0.8c0.4-0.8,0.8-1.6,1-2.3c0.2-0.2,0.4-0.4,0.4-0.6
		c0.6-0.4,0.8-0.8,1-1.4c0.2-0.4,0.2-1,0.4-1.6c0.4-0.6,0.6-1.2,0.8-1.8l1.2-2.7c0-0.2,0-0.6,0-0.8c0.2-0.8,0.4-1.6,1-2.1
		c0.4-0.2,0.6-0.6,0.6-1.2c0.4-1.4,1.2-2.7,1.6-4.3c0.2-0.4,0.2-1,0.4-1.4l1.2-3.3c0.4-0.8,0.8-1.8,1-2.5c0-0.4,0.2-0.6,0.2-1
		c0.2-0.4,0.4-0.8,0.4-1.2c0.4-1,0.6-2,0.8-2.9c0-0.2,0-0.6,0.2-0.8c0.4-1,0.6-2.1,0.6-3.1h0.4c0.2-0.6,0.2-1.4,0.2-2
		c0-1.4,0.4-2.5,0.6-3.9c0.2-0.8,0.6-1.6,1.2-2.1c0.4-0.2,0.6-0.2,0.8,0.2c0.2,0.2,0.2,0.2,0.6,0c0.8-1,0.8-1,1.6-0.4
		c0.4,0.4,0.8,0.6,1.6,0.8C376.3,1.2,376.7,1.8,376.5,2.9z M283,177.8c-0.4,0.4-1.2,1.4-1.6,2v0.2C282,179.6,282.4,179,283,177.8z
		 M286.1,172.7c-0.8,0.8-1.2,1.6-1.8,2.5v0.2C285,174.9,285.7,173.9,286.1,172.7z M291,181.3c-0.2,0.2-0.4,0.4-0.6,0.6l-1.2,1.2
		c-0.4,0.4-0.8,0.8-1.2,1.4c-0.6,0.6-0.6,0.6-0.8,1.2c0.4-0.2,0.6-0.6,0.8-1.2c0.8-0.6,1.6-1.2,2.1-2l0.8-0.8V181.3z M290,187h-0.2
		l0.2,0.2V187z M291.6,163.8c-0.4,0.4-0.6,0.8-0.8,1.2c-0.2,0,0,0.2,0,0.4C291.2,164.9,291.4,164.3,291.6,163.8z M293.9,187.2
		c0,1.4,0.4,2.5,1,3.5c1,1.2,2,2,3.3,2.3c1.6,0.6,3.3,0.6,4.9,0.4c1,0,2-0.4,2.9-0.8c2-0.8,3.9-2,5.7-3.3c0.8-0.8,1.6-1.4,2.3-2
		c0.6-0.6,1.2-1.2,1.8-2c0,0,0.2-0.2,0.2-0.4c0.2-0.4,0.4-0.6,0.8-0.8l0.6-0.6c0.2-0.4,0.6-0.8,0.8-1.4c0.6-0.8,1-1.6,1.4-2.5
		c0.2-0.2,0.2-0.6,0.2-1c0,0,0-0.2,0.2-0.4c0.4-0.8,0.6-1.8,0.8-2.7c0.6-1.8,1-3.5,1-5.3c-0.2-0.6-0.2-1-0.2-1.6
		c-0.2-1-0.8-1.4-1.4-1.4c-0.8,0.2-1.6,0-2.3,0.4h-0.4c-1.4,0.4-2.7,1-4.3,1.8c-2.7,1.4-5.3,3.3-7.8,5.5c-1.6,1.4-3.1,2.7-4.5,4.1
		c-1.8,1.8-3.5,3.5-5.3,5.1l-1,1.2C294.1,185.8,293.9,186.4,293.9,187.2z M294.5,182.1c0.2,0,0.2,0.2,0.2,0c0,0,0.2,0,0.2-0.2h-0.2
		C294.7,181.9,294.7,182.1,294.5,182.1z M294.7,177.6c0.4,0,0.6-0.4,1-0.6c1.4-1.4,2.7-2.5,3.9-3.9c0.6-0.4,1-0.8,1.4-1.4l-1,0.6
		c-1,0.8-2.1,1.8-3.1,2.9c-0.6,0.6-1.4,1.2-2,2C294.7,177.2,294.7,177.4,294.7,177.6z M300.4,176.4c-0.2,0.2-0.6,0.2-0.8,0.2
		c-0.4,0-0.6,0.2-0.8,0.6c0,0.4-0.2,0.8-0.2,1c-0.6,0.2-1,0.6-1.2,1c-0.2,0.2-0.6,0.4-0.8,0.2c-0.2,0-0.4,0.2-0.6,0.4
		c-0.2,0.2-0.4,0.8-0.2,1.4c0.6-0.6,1.2-1,1.6-2c0.4-0.2,0.8-0.4,1.2-1l0.8-0.6c0.4-0.4,0.8-0.6,1-1c1-0.8,1.4-1.2,1.6-1.8
		C301.2,175.3,301,175.5,300.4,176.4z M298,156c-0.6,0.6-0.8,1-1,1.8C297.7,157.1,297.8,156.9,298,156z M297.5,178.6
		c0,0,0-0.2,0.2-0.2h-0.2c0,0,0,0-0.2,0.2H297.5z M298.8,151.9c-0.6,0.8-0.6,0.8-0.8,1.4C298.4,153,298.4,153,298.8,151.9z
		 M302.7,148.3l-0.4,0.4c-0.8,1.2-1.6,2.5-2.3,3.7c-0.2,0.4-0.2,0.6-0.4,0.8C300.6,152.2,302.3,149.3,302.7,148.3z M300.8,148.7
		c0.8-0.6,0.8-0.8,0.8-1.6C301.2,147.8,301,148,300.8,148.7z M303.7,143.9l-1.2,1.6c0,0.2,0,0.4,0.2,0.4
		C303.1,145.2,303.5,144.6,303.7,143.9z M368.5,14.6c-0.2,0.2-0.4,0.6-0.4,0.8c-1,2.7-1.8,5.5-2.7,8.2c-1.2,3.5-2.3,6.8-3.7,10.3
		l-2.9,7.2c-1.4,2.9-2.5,5.9-3.9,8.8c-1.8,4.3-3.7,8.6-5.9,12.9l-3.9,8.6c-1.6,3.1-3.3,6.4-4.9,9.8c-1.2,2.5-2.5,5.1-3.9,7.4
		c-2.5,5.3-5.3,10.3-8,15.2c-1.8,3.1-3.3,6.1-5.1,9c-1.6,3.1-3.3,6.1-5.1,9c-1.2,2.1-2.3,4.3-3.7,6.4c-0.2,0.6-0.6,1-1,1.6
		c-0.4,0.8-1,1.6-1.4,2.5c-1,1.4-1.8,2.7-2.5,4.1c-0.6,1.2-1.4,2.3-2.1,3.5c-1,2-2.1,3.9-3.3,5.9v0.6c0.2-0.4,0.4-0.8,0.6-1
		c0.6-1,1-1.8,1.6-2.5c0.8-1.6,1.8-2.9,2.5-4.3l1.4-2.3c0.6-0.8,1-1.6,1.4-2.3l2.3-3.7c0.8-1.4,1.4-2.5,2.1-3.7l2.7-4.7
		c0.8-1.6,1.8-2.9,2.5-4.5c0.8-1.6,1.8-2.9,2.5-4.5c1-1.6,1.8-3.1,2.5-4.5l5.5-10.1c3.5-6.6,7-13.3,10.3-19.9
		c1.8-3.3,3.3-6.8,4.9-10.1c2.5-5.3,5.1-10.5,7.4-15.8l2.9-7c1.4-2.9,2.5-6.1,3.7-9.2c1.2-2.9,2.3-5.9,3.3-8.8
		c0.6-1.2,1-2.1,1.4-3.3l2.3-7.8L368.5,14.6z M305.1,141.5c0.4-0.2,0.6-0.4,0.8-1.4C305.5,140.5,305.3,140.9,305.1,141.5z
		 M312.7,138.6c-0.6,0.6-0.6,0.6-0.8,1.2C312.3,139.4,312.5,139,312.7,138.6z M317.8,184.8c-0.4,0.2-0.6,0.6-0.8,1
		c-0.4,0.2-0.6,0.6-1,1c-0.2,0.4-0.6,0.6-1,1c-0.2,0.4-0.6,0.6-1,1c-0.2,0.4-0.6,0.6-0.8,1c-0.4,0.2-0.8,0.6-1,1
		C313.7,190.1,317,186.6,317.8,184.8z M314,134.9v0.2C314.2,134.9,314,134.9,314,134.9z M315,134.5c-0.6,0.6-0.8,0.8-0.8,1.2
		C314.8,135.3,314.8,135.3,315,134.5z M315.4,124.1c0.6-0.4,0.6-0.6,0.6-1.2C315.4,123.6,315.4,123.6,315.4,124.1z M317.6,189.9
		c-0.6,0.2-0.8,0.2-1.2,0.4c-0.2,0-0.4,0.2-0.6,0.6v0.6c0,0,0.2,0,0.4,0l1.2-1.2C317.4,190.1,317.4,190.1,317.6,189.9z M317,131.2
		c-0.4,0.4-0.8,1-1,1.8C316.6,132.5,316.8,131.8,317,131.2z M316.2,131.6c0,0,0-0.2-0.2-0.2v0.4C316,131.8,316,131.6,316.2,131.6z
		 M317.6,120.4c0.2,0,0.2-0.2,0.2-0.2h-0.2V120.4z M318.7,128l-0.8,1.2c-0.2,0.2-0.4,0.4-0.2,0.8C318.3,129.4,318.5,128.8,318.7,128
		z M318.7,188.2c-0.4,0.4-0.6,0.6-0.6,1C318.3,188.9,318.5,188.7,318.7,188.2z M319.7,125.1c0,0,0,0-0.2,0.2v0.2
		c0.2,0,0.2,0,0.2-0.2V125.1z M320.5,125.1c-0.2,0-0.4,0.4-0.4,0.6c-0.2,0-0.2,0.2,0,0.2C320.3,125.7,320.5,125.5,320.5,125.1z
		 M322.2,122l-0.4,0.6c-0.2,0.2-0.4,0.6-0.6,0.8c0,0.2,0,0.4,0.2,0.4C321.9,123.4,322.2,122.8,322.2,122z M321.5,122.4v-0.2
		l-0.2,0.2v0.2L321.5,122.4z M325.4,116.7c-0.4,0.4-0.8,0.6-1,1.2c-0.2,0.8-0.6,1.4-1,2c-0.2,0.2-0.2,0.4-0.2,0.6
		c0.2,0,0.2-0.2,0.4-0.4l0.6-1c0.4-0.6,0.8-1.4,1.2-2C325.4,116.9,325.6,116.7,325.4,116.7z M324,109.1c0,0,0,0,0.2,0
		c-0.2,0-0.2,0-0.2-0.2V109.1z M327.1,113.8c-0.4,0.2-0.8,0.6-0.8,1.2C326.7,114.6,326.9,114.4,327.1,113.8z M327.7,111.5
		c-0.4,0-0.4,0.2-0.4,0.6L327.7,111.5z M331,106.6h-0.2c-0.4,0.4-0.6,0.6-0.8,1.2c-0.2,0.6-0.4,1.2-1,1.8c-0.2,0.2-0.2,0.4-0.4,0.8
		c-0.2,0.6-0.4,1-0.8,1.4c0,0.2-0.2,0.4-0.2,0.6s-0.2,0.2,0,0.2c1.2-1.8,2.3-3.5,3.3-5.7V106.6z M329.1,108.7
		c-0.4,0.4-0.4,0.4-0.4,1C328.9,109.3,329.1,109.1,329.1,108.7z M330.4,106.4c-0.4,0-0.4,0.4-0.4,0.8
		C330.4,106.8,330.4,106.8,330.4,106.4z M331.4,104.2l-0.4,0.6C331.6,104.6,331.6,104.6,331.4,104.2z M332.6,103.8
		c-0.4,0.4-0.8,0.8-1,1.4v0.4C332.2,105.2,332.4,104.4,332.6,103.8z M333.8,101.7c-0.4,0.4-0.8,0.6-0.8,1.2
		C333.6,102.5,333.6,102.3,333.8,101.7z M334,99.5c-0.2,0.2-0.4,0.4-0.2,0.8C334,100.1,334.1,99.9,334,99.5z M338.8,91.9
		c-0.4,0-0.4,0.4-0.6,0.6c-0.2,0.4-0.2,0.8-0.4,1.2l-1.8,2.9c-0.2,0.4-0.4,0.8-0.6,1.2c-0.4,0.4-0.6,1-0.6,1.6
		c-0.2,0.4-0.4,0.6-0.8,0.8c0,0.2-0.2,0.6,0,0.8c0.4-0.4,0.4-0.6,0.8-1.6c0.2-0.2,0.4-0.4,0.4-0.6c1-1.6,1.8-3.1,2.5-4.5l1-2.1V91.9
		z M335.1,97.6c-0.4,0.4-0.4,0.4-0.4,0.6L335.1,97.6z M336.1,95.8c0-0.2,0.2-0.2,0.2-0.4c-0.4,0.4-0.4,0.4-0.4,0.8
		C335.9,96,336.1,96,336.1,95.8z M337.1,93.7c-0.2,0.2-0.2,0.2-0.2,0.6C337.1,93.9,337.1,93.9,337.1,93.7z M338.2,91.9v-0.2
		l-0.2-0.2c0,0.2,0,0.2,0,0.4H338.2z M339,90.2c0.2,0,0.4-0.2,0.4-0.4l-0.2-0.2c0,0.2-0.2,0.4-0.2,0.4c-0.2,0-0.2,0.2-0.2,0.2H339z
		 M341.8,86.1c-0.4,0.2-0.6,0.6-0.8,1c0,0.6-0.2,1-0.6,1.4c-0.4,0.6-0.6,1.4-1,2.1c-0.2,0-0.2,0.2,0,0.4
		C340.2,90,341.4,87.4,341.8,86.1z M340,88.4v-0.2h-0.2v0.2H340z M341.2,86.3l-0.2-0.2c0,0.2,0,0.2,0,0.2v0.2
		C341,86.3,341.2,86.3,341.2,86.3z M342.2,83.7c-0.2,0.4-0.4,0.6-0.4,1C342.2,84.5,342.2,84.5,342.2,83.7z M368.3,25.4
		c-0.2,0-0.4,0.2-0.6,0.4c-0.2,0.2-0.2,0.6-0.4,0.8c-0.6,1.8-1.2,3.7-2,5.5l-0.2,0.8c-0.8,1.4-1.2,2.7-1.6,3.9
		c-0.4,0.6-0.6,1.2-0.8,1.8c-0.2,0.2-0.4,0.6-0.4,1c-0.4,0.6-0.6,1.2-0.8,2c-0.4,0.4-0.6,1-0.8,1.6l-0.4,1.6c-0.4,0.6-0.6,1.2-0.8,2
		c-0.2,0.4-0.4,0.8-0.8,1.2c-0.2,0.4-0.4,0.8-0.4,1.2l-0.6,1.4c-0.2,0.2-0.4,0.6-0.6,1v0.4c0.4-0.2,0.6-0.6,0.8-1c0,0,0-0.2,0.2-0.2
		c-0.2,1-0.8,1.6-1,2.3c-0.2-0.2-0.4-0.2-0.4-0.4c-0.6,0.8-1,1.6-1.2,2.5c0,0,0,0.2,0.2,0.2l0.4-0.6h0.2c0,0.2-0.2,0.6-0.2,0.6
		c-0.4,0.8-0.6,1.6-1,2.3c0,0.2-0.2,0.6-0.4,0.8c-0.2,0.4-0.2,0.6-0.4,0.8l-1.8,3.7c-0.2,0.2-0.2,0.6-0.4,1c-0.2,1-0.8,1.8-1.2,2.5
		l-1.2,2.5c-0.4,0.8-0.8,1.8-1.2,2.5l-0.6,1.2c-0.6,1.2-1.4,2.5-2,3.9l-0.6,1.4c-0.4,0.8-1,1.8-1.4,2.5c-0.4,0.8-0.6,1.8-1.2,2.3
		l-1.2,2.3c0,0.2,0,0.4,0,0.4c0.2,0,0.4-0.2,0.4-0.2l1.8-3.7c2.1-3.9,4.1-8,6.1-12.1l5.3-11.7c1.2-2.1,2.1-4.5,3.1-6.8l3.5-8.4
		c1.6-3.7,2.9-7.6,4.3-11.3l1.8-5.1V25.4z M342.9,82.8c0-0.2,0-0.2,0-0.2c-0.2,0-0.2,0.2-0.2,0.2V83L342.9,82.8z M344.5,79.4
		c0-0.2,0.2-0.2,0.2-0.4h-0.2c0,0,0,0.2-0.2,0.2C344.5,79.3,344.5,79.4,344.5,79.4z M345.9,76.9l0.6-1.2c0.2-0.4,0.4-1,0.6-1.6
		c0.2,0,0.2-0.2,0-0.2c-0.6,1.2-1.6,2.3-2,4.1C345.7,77.7,345.7,77.3,345.9,76.9z M347.8,72.4c-0.2,0.2-0.2,0.2-0.2,0.6
		C347.8,72.8,348,72.6,347.8,72.4z M348.6,71.2c0-0.2,0-0.2-0.2-0.2v0.4L348.6,71.2z M349.4,69.3c-0.4,0.2-0.4,0.4-0.4,0.6
		C349.4,69.7,349.4,69.5,349.4,69.3z M350,68.1v-0.2l-0.2,0.2H350z M350.7,66.6c0,0,0,0,0-0.2c-0.2,0-0.2,0.2-0.2,0.2H350.7z
		 M352.1,63.4c-0.2,0-0.2,0-0.2,0.2v0.2C352.1,63.6,352.1,63.6,352.1,63.4z M352.7,61.7c0,0.4-0.2,0.6-0.2,0.8
		C352.9,62.1,352.9,62.1,352.7,61.7z M353.5,60.9c0-0.2,0-0.4,0.2-0.6h-0.2c-0.2,0.2-0.2,0.2-0.2,0.4v0.2H353.5z M354.6,58
		c-0.4,0.4-0.6,0.8-0.6,1.4C354.4,59,354.4,59,354.6,58z"
      />
      <path
        id="XMLID_164_"
        d="M388,167.1c0,0.6-0.2,1-0.4,1.6c0,0.2-0.2,0.4-0.4,0.6c-3.1,2.5-6.4,5.1-9.8,7.6c-4.5,3.5-9.2,7-13.5,10.5
		c-7.6,5.7-15,11.5-22.6,17.2c-2.3,1.8-4.7,3.3-7.2,4.7c-1.2,0.6-2.1,1.2-3.5,1.4c-1.6,0.2-2.7,0-3.7-1l-1-0.6
		c-1.8-1.2-2.5-2.9-2.1-5.3c0.4-2.9,0.8-6.1,2.1-8.8c0.8-1.2,1.4-2.3,1.8-3.7c1.8-4.1,4.1-8.2,6.4-12.1c2.3-4.3,4.7-8.4,7.2-12.5
		c2.7-3.9,5.5-8,8.6-11.9c0.4-0.4,0.8-1,1.6-1.2c2.1,1.2,2.5,2,1.6,4.3c-0.8,2.3-2,4.7-3.3,7c-1,1.6-1.8,3.1-2.5,4.7
		c-0.2,0.2-0.4,0.6-0.4,0.8h0.4c-0.4,0.4-0.6,0.6-0.6,1c-0.8,0.6-1.2,1.2-1.8,2c-0.6,1.6-1.6,3.1-2.5,4.7c-0.2,0.6-0.4,1.2-0.8,1.8
		c-0.2,0.4-0.4,0.6-0.4,0.8c0.2,0.2,0.2,0.2,0.4,0.2h-0.4c-1.2,1-1.8,2.3-2.1,3.7c-0.6,0.8-1.4,1.8-1.4,2.9c-0.8,0.8-1.4,2-1.6,2.9
		c-1,1.4-1.8,2.5-2,4.1c0,0.2,0,0.4-0.4,0.6c-1.8,2.3-2.3,5.1-3.3,7.6c-0.2,0.8-0.2,1.6-0.2,2.3c0,0.4,0.6,0.6,1,0.6
		c0.4-0.2,0.8-0.4,1-0.6l3.7-2.3c1.8-1.2,3.3-2.5,5.1-3.7c4.1-3.1,8.4-6.2,12.5-9.2c3.1-2.3,6.2-4.3,9.2-6.6
		c4.5-3.3,9.2-6.6,13.7-10.1l8.2-5.9c1-0.8,1.8-1,2.9-0.4L388,167.1z M332.2,195.2c0,0,0,0,0,0.2V195.2z M333.2,193
		c0.4-0.4,0.4-0.8,0.6-1.2c0.2-0.4,0.2-0.8,0.4-1v-0.2c-0.2,0.4-0.2,0.8-0.4,1.2c-0.2,0.4-0.2,0.6-0.6,1V193z M335.1,188.9
		c0.2,0,0.2,0,0.4-0.2c0,0-0.2,0-0.2-0.2C335.3,188.7,335.1,188.7,335.1,188.9z M337.5,185c0-0.4,0.2-0.6,0.2-1
		C337.7,184.3,337.5,184.6,337.5,185z M359.7,143.9c-0.6-1-1.4-1.8-1-2.9c0.2-1,0.6-1.8,1-2.5c2-3.3,4.5-6.4,7-9.4
		c0.6-0.8,1.4-1.4,2.1-2c0.8-0.6,1.6-0.6,2.3,0c0.6,0.6,1.2,1,1.8,1.4c-0.6,0.8-1,1.4-1.6,2c-0.8,0.4-1.4,0.8-1.8,1.8
		c-0.4,0.2-1,0.4-1,1.4c0.4-0.8,0.6-1,1.2-1.4c0.4-0.6,1-1.2,1.6-1.8c0.8-0.2,1.2-0.4,2.1-0.6c-0.6,0.6-0.6,0.8-0.8,1
		c-1.8,1-3.3,2.5-4.5,4.3c-1.4,2-2.7,3.9-3.7,6.1c-0.8,1.2-1.6,2.3-2.5,3.3C361.1,144.6,360.3,144.6,359.7,143.9z M368.9,129.2h0.2
		c0.4,0.2,1-0.2,1-0.8h-0.2c-0.4-0.2-0.6-0.2-0.8,0.2C368.9,128.8,368.9,129,368.9,129.2z"
      />
      <path
        id="XMLID_172_"
        d="M462.2,173.3c-0.2,0-0.4,0.2-0.6,0.4c-2,1-3.7,2-5.7,3.1c-3.3,2.5-7.2,4.9-10.7,7.2
		c-6.2,4.5-12.5,9.2-18.5,13.9c-3.5,2.5-6.8,5.1-10.3,7c-1.4,0.8-2.5,1.6-4.3,1.6c-2,0.2-3.9-0.2-5.7-2c-0.8-0.6-1.4-1-2-1.6
		c-1.6-1.4-2.3-2.7-2.7-4.5c-0.4-2.9-0.2-5.9,0.4-8.6c0.6-4.1,1.4-8.6,1.8-12.7c0.2-0.6,0.2-1.4,0-1.8c0-0.4-0.2-0.6-0.4-0.6
		c-0.6,0-1,0.2-1.4,0.4l-3.3,2.1c-2.5,2-5.1,3.7-7.4,5.9c-5.3,4.1-10,8.8-14.6,13.7c-1.6,1.8-3.1,3.5-4.7,5.1c-0.6,0.6-1,1-1.8,1.4
		c-0.8,0.2-2.7-0.2-3.7-1.2c-0.6-0.8-1.6-1-2.1-1.6c-1.2-0.6-1.6-1.6-1.2-2.1c1-2.7,1.8-5.5,2.9-8c1-2,1.8-4.1,2.3-6.2
		c0.4-1.4,1.2-2.5,1.6-3.7l3.9-10.1c0.8-2,1.6-3.7,2.3-5.7c0.2-0.4,0.4-0.8,0.4-1.2c0.4-1.8,1.2-3.5,2-5.1
		c2.3-5.9,4.7-11.3,7.2-17.2c2.3-5.1,4.5-10,6.6-15c2.1-4.9,4.5-9.6,6.6-14.4c1.6-2.9,2.7-5.9,4.3-8.8c1-2.3,2.3-4.9,3.5-7.4l7-13.5
		c2.7-4.9,5.3-10,7.8-14.8c1.6-2.9,3.1-5.7,5.1-8.6c0.2-0.6,0.8-1.2,1.2-1.8c0.4-0.2,0.8-0.2,1.2,0h0.6c0.8,0.4,1,1,0.8,1.6
		c-1,2.5-1.8,5.3-2.9,7.6c-1,2.5-1.8,5.1-2.9,7.4c-1.4,3.7-2.9,7-4.7,10.5c-0.8,1.8-1.6,3.7-2.5,5.7c-1.4,2.7-2.7,5.7-3.7,8.6v-0.2
		c-1,1.8-2.1,3.1-2.3,5.3c-0.4,0.4-0.6,1-0.8,1.8c0,0.2-0.2,0.4-0.4,0.6c-0.4,0.2-0.4,0.8-0.6,1.4c-1.4,2.5-2.9,5.5-3.7,8.6
		c-0.6,0.4-0.8,1-1,1.8h0.2c-0.8,0.8-1.2,1.6-1.2,2.7c-0.4,0.4-0.8,1-0.8,1.8c0,0.2-0.2,0.2-0.4,0.4c-1.4,2-2,4.3-2.7,6.4l-0.6,1.4
		c-1.2,1.8-2,4.1-2.7,6.4c-0.4,0.4-0.6,0.8-0.6,1.4c-0.2,0.4-0.2,0.8-0.2,1.4l-0.4,0.4c-0.8,0.8-1,1.8-1.2,2.7
		c-0.4,0.4-0.6,0.8-0.6,1.4c0.2,0,0.4-0.2,0.6,0h-0.6v0.2c-0.4,0.2-0.4,0.6-0.4,1c0,0.2-0.2,0.4-0.2,0.6c-0.6,0.4-0.6,1-0.8,1.6h0.2
		c-0.6,0.2-0.6,0.8-0.6,1.4c-1,0.8-1.4,2-1.4,3.1c-0.4,0.4-0.6,0.8-0.6,1.4l-0.6,1.4h0.2c-0.4,0.4-0.6,0.6-0.6,1.2
		c0,0.2-0.2,0.4-0.4,0.4c-0.4,0.8-0.6,1.6-0.6,2.3c0,0.2-0.2,0.4-0.2,0.6c-0.8,0.8-1,1.8-1.4,2.7c-0.4,0.4-0.4,1-0.6,1.4h0.2
		c-0.4,0.4-0.6,0.8-0.6,1.4c-0.4,0.4-0.4,1-0.4,1.4c-0.4,0.4-0.4,0.8-0.6,1.4h0.2c-0.2,0-0.4,0.2-0.4,0.4c-0.6,0.4-0.6,0.8-0.8,1.4
		c-0.2,0.2-0.2,0.6,0,1c-0.6,0.2-0.6,0.6-0.6,1.2c0,0.2,0,0.4-0.2,0.6l-0.6,1.6c-0.2,0.2-0.4,0.8-0.6,1.2c-0.4,0.4-0.4,1-0.6,1.4
		c-0.2,0.4-0.4,0.8-0.4,1.4c-0.2,0.2-0.2,0.2-0.2,0.4c0,0.4-0.2,0.8-0.2,0.8c-0.6,0.4-0.6,1-0.6,1.6h0.6c-0.2,0.2-0.4,0.2-0.4,0.4
		c-0.2,0.4-0.2,0.6-0.2,0.8l-0.4,0.6c0-0.2,0-0.4-0.2-0.6c0,0.2-0.2,0.4-0.4,0.6v1c0,0,0.2,0,0.4,0c0.2,0.4,0.6,0.6,0.8,0.4
		c0.6-0.2,0.8-0.4,1.2-0.8c3.7-2.9,7.2-6.1,10.9-9c1.8-1.4,3.9-2.5,5.7-3.7c0.8-0.6,1.8-1,2.9-1.2c1-0.4,2.3-0.2,3.3,0.4
		c2.1,1,4.1,2.1,5.9,3.9c1.2,1,1.8,2.3,2.1,3.7c0.4,2.1,0.4,4.3-0.2,6.1c-0.2,1-0.4,2.1-0.4,3.5c0,0.4,0,1-0.2,1.6H411
		c0.4,0.8,0.4,1.4,0.2,2l-0.2,0.8c0,0.4-0.2,0.8-0.2,1c-0.4,0.8-1,1.8-0.6,2.9c0.2,0.8-0.2,1.4-0.4,2.1c0.6,0.6,0,1.4,0.2,2.1
		c0,0.4,0.6,0.8,1,0.6c1,0,1.8-0.8,2.7-1.4c4.9-3.5,10-6.6,15-10.1c2.3-1.6,4.7-3.3,7.2-4.9c2.7-2,5.9-3.9,8.8-5.7
		c2.7-1.8,5.7-3.7,8.6-5.5c1.2-0.8,2.1-1.2,3.5-1.6c1,0,1.6-0.2,2.5,0.4c1,0.2,2.1,0.6,2.7,1.8H462
		C462.4,172.5,462.6,173.1,462.2,173.3z M374.9,177c0.2,0.2,0.2,0.6,0.4,1v-1c0-0.2,0.2-0.4,0.2-0.6h-0.2c0-0.2,0-0.6,0-0.8
		c0.2,0.2,0.2,0.4,0.4,0.8c0.2-0.2,0.2-0.6,0.2-0.8c0-0.2,0.2-0.2,0.2-0.4h-0.4c0-0.4,0.2-0.8,0.2-1c0.2,0.2,0.2,0.6,0.2,0.8
		c0.2-0.2,0.2-0.6,0.2-0.8h-0.4c0.2-0.2,0.2-0.4,0.4-0.6c0,0.2,0,0.4,0.2,0.4c0,0,0.2-0.2,0.2-0.4c0.2-0.4,0.2-0.6,0.2-0.8l0.4-0.4
		c-0.2,0-0.4,0-0.4,0c0-0.4,0-0.8,0.2-1l0.2,0.8c0-0.2,0.2-0.6,0.2-0.8h-0.4c0-0.2,0.2-0.4,0.2-0.6l-0.2-0.8c0.2,0,0.4-0.2,0.8-0.4
		c-0.2-0.4-0.2-0.6-0.2-1l0.6-0.6c0-0.2-0.2-0.4-0.2-0.6c0.2,0,0.4-0.2,0.4-0.2l0.4,0.8c0.4-0.4,0.6-0.6,0.4-1.2h-0.4
		c0-0.4,0-0.6,0-1l0.6-0.6c0,0.4,0.2,0.6,0.2,1c0.2-0.4,0.2-0.6,0.2-1h-0.4c-0.2-0.2-0.2-0.4-0.4-0.8c0.4-0.2,0.6-0.4,1-0.4
		c-0.2-0.4-0.2-0.8-0.4-1c-0.4,0.2-0.4,0.8-0.6,1.4c-0.4,0.4-0.6,0.6-0.6,1.2c-0.4,0.4-0.6,1-0.6,1.6c-0.2,0.4-0.4,0.6-0.2,1.4
		c-0.6,0.2-0.8,0.8-0.6,1.4c-1,0.6-1.2,1.6-1.2,2.7c-1,1.2-1.6,2.7-1.8,4.5c-0.4,0.4-0.6,0.8-0.6,1.4c-0.4,0.2-0.6,0.8-0.6,1.4
		c-0.2,0.4-0.4,0.6-0.4,1.2c-0.4,0.4-0.6,1-0.6,1.6c-0.6,0.6-1.2,1.6-1.2,2.7c-0.4,0.4-0.6,0.6-0.6,1.2c-0.2,0.4-0.4,1-0.4,1.6
		c-0.8,0.8-1.2,2-1.6,2.9c-0.4,0.8-0.6,1.4-0.6,2.3c1,0,0.8-1.2,1.4-1.6c0,0,0.2,0,0.2-0.2c0.2,0.2,0.4,0.2,0.4,0
		c0.6-0.4,0.6-0.8,0.6-1.2c0.4-0.4,0.4-0.8,0.4-1.6c0.4-0.4,0.6-0.6,0.6-1.2c0.4-0.4,0.6-1,0.6-1.6c0.4-0.4,0.6-0.6,0.6-1.2
		c0.4-0.4,0.4-1,0.4-1.6c0.4-0.4,0.4-0.6,0.4-1.2h-0.4c0-0.4,0-0.6,0-1c0-0.2,0.2-0.4,0.6-0.6c0,0.4,0.2,0.6,0.2,1
		c0-0.4,0.2-0.6,0.2-1h-0.4c-0.2-0.2-0.2-0.4-0.4-0.8c0.4-0.2,0.6-0.4,0.8-0.4c0-0.4,0-0.8-0.2-1c0.4-0.2,0.6-0.4,0.8-0.6v-0.6
		L374.9,177z M369.5,191.1c0,0.2,0.2,0.6,0.2,0.8h-0.4L369.5,191.1z M370.1,189.7c0,0.2,0.2,0.6,0.2,1h-0.4
		C369.9,190.3,369.9,189.9,370.1,189.7z M370.4,188.4c0,0.2,0.2,0.4,0.2,0.8h-0.4c0-0.2,0-0.6,0-0.6L370.4,188.4z M371,187
		c0,0.2,0.2,0.6,0.2,1h-0.6c0-0.4,0-0.6,0-1H371z M371.4,185.6l0.4,0.8c-0.2,0-0.4,0-0.6,0c0-0.2,0-0.4-0.2-0.6
		C371.2,185.8,371.4,185.6,371.4,185.6z M372,184.3c0.2,0.2,0.2,0.6,0.4,1h-0.6C371.8,184.8,372,184.4,372,184.3z M372.6,182.9
		c0.2,0.4,0.2,0.4,0.2,0.8h-0.4c0-0.2,0-0.4-0.2-0.6C372.4,183.1,372.6,183.1,372.6,182.9z M374.3,179.8c-0.2,0-0.4,0-0.4,0l0.2,0.8
		L374.3,179.8z M374.9,178.2h-0.4c0,0.4,0,0.6,0.2,1C374.7,178.8,374.7,178.6,374.9,178.2z M375.3,177c0,0-0.2,0-0.4,0l0.4-0.6V177z
		 M376.5,184.8c1.4-2.9,2.1-5.9,3.5-8.8c1.2-3.7,2.9-7.4,4.3-10.9c3.1-8.4,6.4-16.8,9.8-25c1.2-2.3,2-4.7,2.9-7
		c0.6-1.2,1.2-2,1.4-3.3c0.2-0.4,0.4-0.8,0.4-1.4c0.6-0.4,0.8-1.2,0.8-1.8c0.4-0.4,0.6-0.8,0.6-1.4c-0.6,0.2-0.8,0.8-0.6,1.4
		c-0.8,0.2-1,0.8-1,1.8c-0.4,0.2-0.4,0.8-0.4,1.2c-1,0.8-1.2,2.1-1.6,3.1c-0.6,0.8-0.8,1.6-1.2,2.5c-2.9,6.8-5.7,13.5-8.4,20.1
		c-2,4.9-3.9,10-6.1,15c-1.8,4.7-3.5,9.4-5.5,14.2c-0.2,0.8-0.6,1.4-0.4,2.3C376.1,186.6,376.1,185.6,376.5,184.8z M375.3,175.7
		c0.2-0.2,0.2-0.2,0.4-0.4c0,0.2,0,0.2,0.2,0.4H375.3z M376.3,173.7c-0.2-0.2-0.2-0.6-0.4-0.8h0.6c0,0.2,0.2,0.4,0.2,0.8H376.3z
		 M377.5,193.8c-0.6,0.2-1.2,0.6-1.4,1.4C376.7,194.8,377.3,194.4,377.5,193.8z M376.9,172.5v0.4c-0.2,0-0.2,0-0.4,0
		C376.5,172.7,376.7,172.7,376.9,172.5z M377.5,171c0,0.2,0,0.4,0,0.4c0.2,0,0.4-0.2,0.4-0.4H377.5z M378.8,192.5
		c-0.8,0.2-1,0.6-1.2,1.2C378.1,193.6,378.6,193.2,378.8,192.5z M379,181.5h0.4c0,0.2-0.2,0.6-0.2,0.8
		C379,182.1,379,181.7,379,181.5z M379.2,187c0.4-0.2,0.8-0.6,1-1C379.8,186.2,379.4,186.4,379.2,187z M380.4,191.1
		c-0.6,0-1,0.4-1.2,0.8C379.8,191.9,380.2,191.5,380.4,191.1z M381,160.1c-0.8,1-1.4,2-1.4,3.1c0.4,0,0.6-0.2,1-0.4v1
		c0-0.4,0.2-0.6,0.2-1c0.2,0,0.2-0.2,0.4-0.2h-0.4v0.2h-0.2v-0.2c0.2-0.4,0.2-0.8,0.2-1c0.2-0.2,0.4-0.4,0.4-0.6
		c0.2,0.2,0.2,0.4,0.2,0.4c0.2,0,0.2-0.2,0.4-0.4c0.4-0.4,0.6-0.8,0.4-1.2h-0.4c0-0.4,0-0.6-0.2-1c0.4-0.2,0.6-0.4,0.8-0.6
		c0.2,0.4,0.2,0.6,0.2,1c0.2-0.4,0.2-0.6,0.2-1h-0.4c-0.2-0.4-0.2-0.6-0.4-0.8c-0.4,0.2-0.6,0.6-0.6,1.2
		C381,159.1,380.8,159.7,381,160.1z M380.6,177.4h0.2c0,0.4,0,0.8,0,0.8C380.6,178.2,380.6,177.8,380.6,177.4z M381.8,189.7
		c-0.6,0.2-1,0.4-1.2,1C381.2,190.5,381.6,190.3,381.8,189.7z M381,177.4c-0.2-0.2-0.2-0.2-0.2-0.4h0.6
		C381.2,177.2,381,177.2,381,177.4z M381.2,161c0-0.2-0.2-0.6-0.2-1h0.4c0.2,0.4,0.2,0.6,0.4,1H381.2z M381,161.6
		c0,0.2,0,0.6,0.2,0.8l0.2-0.8H381z M382.2,157.5c0.2-0.2,0.4-0.4,0.8-0.4c0,0.2,0.2,0.4,0.2,0.6v-0.6c0,0,0,0-0.2,0
		c-0.2-0.4-0.2-0.8-0.4-1C382.2,156.5,382,156.9,382.2,157.5z M383.1,188.5c-0.4,0-0.8,0.2-1,0.6
		C382.5,188.9,382.9,188.7,383.1,188.5z M382.9,173.1h-0.6c0.2-0.2,0.4-0.2,0.6-0.2V173.1z M383.3,154.2c0.4,0,0.6-0.2,0.8-0.4
		c0-0.2-0.2-0.6-0.2-1c0.2-0.2,0.6-0.4,0.8-0.4c0.2,0.2,0.2,0.6,0.4,0.8c0-0.2,0-0.6,0.2-0.8h-0.6c0-0.2-0.2-0.6-0.2-0.8
		c-0.4,0.4-0.6,0.8-0.6,1.2C383.5,153.2,383.3,153.6,383.3,154.2z M384.5,187c-0.4,0.2-0.8,0.4-1,0.8
		C384.1,187.8,384.3,187.4,384.5,187z M384.5,151.5c0.2,0,0.6-0.2,0.8-0.4c-0.2-0.4-0.2-0.6-0.2-1l0.6-0.4c0,0,0.2-0.2,0.4-0.4v0.4
		c0.2,0,0.2-0.2,0.4-0.4h-0.4c-0.2,0-0.2,0-0.2,0c0-0.2,0-0.6-0.2-1c0.4-0.4,0.6-0.8,0.6-1.2c-0.4,0.2-0.6,0.6-0.6,1.2
		c-0.4,0.6-0.8,1.2-0.6,1.8C384.5,150.5,384.5,150.9,384.5,151.5z M385.7,186c-0.4,0.2-0.8,0.4-1,1
		C385.1,186.8,385.5,186.4,385.7,186z M385.3,165.7c0.2-0.2,0.4-0.4,0.4-0.2S385.5,165.7,385.3,165.7z M385.7,149.9
		c0,0,0.2,0.4,0.2,0.6c0.2-0.2,0.2-0.6,0.2-0.6H385.7z M386.6,185.2l-0.6,0.4C386.3,185.6,386.4,185.4,386.6,185.2z M386.8,163h-0.6
		c0.2,0,0.4-0.2,0.6-0.2V163z M386.8,145.6l0.8-0.6c0.2,0,0.2-0.2,0.4-0.4c0,0.2,0.2,0.4,0.2,0.4v-0.4c0,0,0,0-0.2,0
		c-0.2-0.2-0.2-0.4-0.4-0.8C387,144.2,386.8,144.8,386.8,145.6z M387.4,161.6h-0.2l0.2-0.2V161.6z M387.8,143.9
		c0.2-0.2,0.4-0.2,0.4-0.4v-1C387.8,142.9,387.6,143.3,387.8,143.9z M389.2,182.9c-0.6,0.4-1.2,0.4-1.2,1.4
		C388.6,183.9,389,183.5,389.2,182.9z M388.4,142.1h0.4c0-0.2-0.2-0.6-0.2-0.8C388.6,141.5,388.6,141.7,388.4,142.1z M389.4,156.1
		c0,0.2-0.2,0.6-0.2,0.8c-0.2-0.2-0.2-0.6-0.4-0.8H389.4z M389,140.5c0.2,0,0.4,0.2,0.4,0.2c0.2-0.2,0.4-0.4,0.4-0.6
		c0.2,0.2,0.2,0.4,0.2,0.4c0.2,0,0.2-0.2,0.4-0.4h-0.6c0-0.4-0.2-0.6-0.2-0.8l0.8-0.6c0.2,0,0.2-0.2,0.4-0.4c-0.2-0.4-0.2-0.6-0.2-1
		l0.4-0.4c0-0.2,0-0.6-0.2-0.8c-0.2,0.4-0.2,0.8-0.2,1.2c-0.8,0.4-1,1.2-1,2C389,139.6,389,139.9,389,140.5z M390.4,181.9
		c-0.4,0.2-0.8,0.6-1,1C389.8,182.7,390.2,182.5,390.4,181.9z M391.3,180.9c-0.4,0.2-0.6,0.6-0.8,1
		C390.7,181.7,391.3,181.5,391.3,180.9z M391.1,135.7h0.6c0-0.2,0.2-0.4,0.2-0.6c0.2,0.2,0.2,0.4,0.2,0.6v-0.6
		c-0.2-0.2-0.2-0.6-0.4-0.8C391.3,134.5,391.1,135.1,391.1,135.7z M392.7,180.2c-0.6,0.2-1,0.6-1.2,0.8
		C392.1,180.7,392.5,180.7,392.7,180.2z M393.3,131c0.2-0.4,0.2-0.8,0.2-1.2C393.5,130.2,393.3,130.6,393.3,131z M395.6,139.8
		c0.2-0.2,0.4-0.2,0.4-0.2v0.2H395.6z M396,177.6c0,0,0,0.2-0.2,0.2h0.2V177.6z M396,139.6v-0.2h0.2
		C396.2,139.6,396,139.6,396,139.6z M400.1,125.1c0.4-0.2,0.6-0.8,0.4-1.2C400.3,124.1,400.1,124.5,400.1,125.1z M400.9,123.4
		c0.8-0.8,1.2-2,1.4-3.1c0.6-0.6,1-1.2,0.8-1.8c-0.6,0.4-0.8,0.8-0.8,1.8C401.3,121.2,400.9,122,400.9,123.4z M403.2,118.5
		c0.6-0.4,0.6-1.2,0.6-2c0.4-0.2,0.6-0.8,0.6-1.4c1.6-1.4,2-3.5,2.5-5.5c0.6-0.2,0.6-0.8,0.6-1.2c-0.4,0.2-0.8,0.6-0.6,1.2
		c-1.2,1.6-2,3.5-2.5,5.5c-0.4,0.4-0.6,1-0.6,1.4C403.4,116.9,403,117.5,403.2,118.5z M403.4,122.4c-0.2-0.2-0.2-0.2-0.2-0.4
		C403.4,122.2,403.4,122.2,403.4,122.4z M410.8,105.8h-0.6C410.5,105.6,410.6,105.6,410.8,105.8z M410.6,105.6v-0.4
		c0.2,0.2,0.2,0.4,0.2,0.6C410.8,105.6,410.6,105.6,410.6,105.6z"
      />
      <path
        id="XMLID_233_"
        d="M521.1,172l-3.5,3.3c-0.4,0.4-0.8,0.8-1.6,1v-0.4c0,0.2-0.2,0.4-0.4,0.4l-0.4,0.6c-0.2,0-0.4,0.2-0.4,0.4
		c-0.2,0-0.4,0.2-0.4,0.4l-1.2,0.6c-2.7,2.9-5.7,5.7-8.6,8.6c-0.2,0.2-0.4,0.6-0.6,1c-0.2,0-0.4,0.2-0.4,0.4
		c-0.2,0.2-0.4,0.2-0.4,0.4c0.2,0,0.2-0.2,0.4-0.4c0.2,0,0.2-0.2,0.4-0.4c0.8-0.2,1.2-1,1.8-1.4c0.6-0.8,1.2-1.6,2.1-2
		c-0.2,0.8-0.8,1.2-1.4,1.6c-3.1,3.5-6.4,7-9.8,10.5c-2,2.3-3.9,4.5-6,6.6c-2.9,3.5-6.2,6.6-9.6,9.6c-0.8,0.8-1.8,1.4-2.7,2
		c-1.8,0.8-3.5,0.8-5.1-0.6l-1-0.6c-1.2-1-1.8-2.1-1.8-3.9c0-1.6,0.2-2.9,0.6-4.5c1.4-6.1,3.7-11.9,5.9-17.8
		c0.6-2.1,1.4-4.1,2.1-6.2c0.2-0.4,0.4-1,0.4-1.4c0.2-0.4,0-0.6-0.2-0.8c-0.8-0.2-0.8-0.8-0.8-1.6v-2.3c0-0.6-0.6-0.6-1-0.6v0.2
		c-0.2,0-0.2,0.2-0.4,0.4v-0.4c-0.2,0-0.4,0.2-0.4,0.4h-0.2c-0.2,0.4-0.4,0.8-0.8,1.2c0,0.2-0.2,0.4-0.4,0.4l-0.4,0.6
		c0.2,0,0.2,0,0.4,0c-0.2,0-0.2,0.2-0.4,0.4c-4.5,5.5-9.8,10.1-16,13.7c-1.4,0.8-2.9,1.4-4.5,1.8c-1.2,0.2-2.1,0.2-3.3-0.2
		c-2.3-0.8-3.7-2.5-4.7-4.7c-0.4-0.6-0.4-1.4-0.2-2.1c0.2-1.8,0.6-3.1,1.2-4.5c0.8-1.6,1.6-2.9,2.3-4.1c2.3-3.1,5.1-6.1,8.2-8.2
		c3.5-2.3,7-3.7,11.3-3.5c1.2,0,2.3,0.4,3.5,0.8c1.2,0.4,1.4,0.4,2.3-0.8c1.4-2.1,2.9-4.1,4.3-6.2l0.8,0.4v-0.2
		c3.1-3.3,5.9-7.2,9.2-10.3c0.6-0.8,1.4-1.6,2.1-2.1c1-0.8,1.6-0.8,2.5-0.2c0.6,0.4,1,0.6,1.4,1c1.8,1.2,2.1,2.5,1.6,4.5
		c-1,2.7-1.8,5.5-3.1,7.8c-0.4,0.6-0.6,1-0.8,1.6l0.6-0.8c0,0.6-0.2,1.2-0.8,1.8V162c-0.2,1-0.6,1.8-1,2.7l-0.2,0.8l1,0.6
		c0,0.2-0.2,0.6-0.4,0.8c-0.2,0.6-0.2,1-0.4,1.4c-0.8,1-1.6,2.3-1.6,3.7c-0.8,1-1.4,2-1.4,3.1c-0.2,0.2-0.4,0.4-0.4,0.8v-0.2
		c-0.6,1-1,2-1.4,3.1c-0.4,0.6-0.8,1.4-0.8,2.1c-0.4,0.4-0.4,0.8-0.6,1.4h0.2c-0.6,0.6-0.8,1.4-1,2.1c-0.2,0.2-0.2,0.6-0.4,0.8
		c-0.6,0.4-0.8,1-1,1.6l-2.9,8c-0.4,0.8-0.8,1.8-0.8,2.7c-1.4,2.5-2.1,5.1-2.5,7.8c-0.6,1-0.8,2.1-0.8,3.3h-0.2c1,0.8,1,0.8,1.4,0.4
		l1.8-1.4c3.1-3.3,6.4-6.8,9.8-10.1c2.9-2.9,5.9-5.9,8.8-8.6c3.1-3.3,6.4-6.4,9.8-9.6c3.1-2.9,6.1-6.1,9.4-8.8
		c0.4-0.2,0.6-0.4,0.8-0.6c1.2-1,1.4-1,2.5,0C521.9,170.8,521.9,171.2,521.1,172z M467,176.4l3.7-4.7c0.6-0.6,1-1.4,1.6-2.1
		c0.2-0.2,0-0.6-0.4-0.8h-1c-1.6,0-3.3,0.4-4.9,1.2c-1.8,0.8-3.5,2-5.1,3.1c-0.6,0.4-1.2,1-2,1c0.4-0.8,1.4-1.2,1.8-2
		c0.6-0.2,1-0.4,1.2-1c-0.6,0.2-1,0.4-1.4,0.8c-2.1,1.4-4.1,3.1-5.5,5.7c-0.6,0.2-1,1-1.2,1.6c0.6-0.2,1-0.8,1.2-1.6
		c0.8-0.4,1.2-1,1.6-1.4c0.6-0.6,1.2-1.4,2-1.8c0,0.8-0.6,1.4-1,2c-1.8,1.4-2.9,3.1-4.1,5.1c-1.2,1.8-1.6,3.9-2,5.9
		c-0.2,0.8,0.4,1.2,1.2,1c1.2-0.6,2.5-1.2,3.7-2.1C460.4,183.3,463.9,180,467,176.4z M453,180.4c0.6-0.2,0.8-0.6,1-1
		C453.6,179.6,453.4,180,453,180.4z M453.6,175.5h0.4c0-0.2,0-0.2,0-0.4h0.4c0,0.2,0,0.2,0,0.4l0.4-0.4c0.2-0.2,0.2-0.4,0.4-0.4
		h-0.4c0,0,0,0.2,0,0.4h-0.4c0-0.2,0-0.4,0-0.4c-0.2,0-0.2,0.2-0.4,0.4C453.8,175.1,453.6,175.3,453.6,175.5z M454.4,174.5h0.4v-0.2
		C454.6,174.3,454.6,174.5,454.4,174.5z M454.8,174.1h0.4c0,0.2,0,0.4,0,0.4c0.2,0,0.4-0.2,0.4-0.4h-0.4c0,0,0-0.2,0-0.4
		C455.1,173.9,454.9,174.1,454.8,174.1z M455.3,173.7h0.2c0-0.2,0-0.2,0-0.4C455.5,173.5,455.3,173.5,455.3,173.7z M455.7,174.1
		c0,0,0.2-0.2,0.4-0.4c1-0.8,2.1-1.6,2.5-2.9c0.2,0,0.2-0.2,0.4-0.4c0.4,0,0.6-0.2,0.8-0.4c-0.2,0-0.6,0-0.8,0.4l-0.6,0.4
		c-1.2,0.4-2.1,1.4-2.7,2.5c0,0.2,0.2,0.2,0.4,0.4h-0.4V174.1z M465.9,181.5v0.2c0.2,0,0.2-0.2,0.4-0.2H465.9c0-0.2,0.2-0.4,0.4-0.6
		v0.4c0.2,0,0.2-0.2,0.4-0.4s0.4-0.2,0.4-0.4h-0.4c0.2-0.2,0.4-0.2,0.4-0.4c0,0.2,0,0.2,0.2,0.4c0-0.2,0.2-0.2,0.2-0.4H467
		c0.2-0.2,0.4-0.4,0.6-0.4v0.4c0-0.2,0.2-0.4,0.4-0.4c0.8-0.6,1.4-1.2,1.6-2.1h-0.2h-0.2c0.2-0.2,0.4-0.4,0.6-0.4v0.4
		c0.2,0,0.2-0.2,0.4-0.4l0.4-0.4c-0.2,0-0.4,0-0.4,0c0-0.2,0.2-0.4,0.4-0.6v0.4c0.2,0,0.2-0.2,0.4-0.4c0.4-0.2,0.8-0.8,0.8-1.2
		l0.4-0.4c0,0-0.2,0-0.4,0v0.4c0,0-0.2,0-0.4,0c0.2-0.2,0.4-0.4,0.4-0.6c0.2,0,0.4-0.2,0.6-0.4v0.4c0,0,0.2-0.2,0.4-0.4h-0.4
		c0-0.2,0-0.4,0-0.8c0.2,0,0.2-0.2,0.4-0.4h0.4c0,0.2,0,0.6,0,0.8c0.4-0.4,0.8-0.8,0.8-1.2h-0.4c0.2-0.2,0.2-0.4,0.4-0.4v0.2
		c0.2,0,0.4-0.2,0.6-0.2h-0.6c0-0.4,0-0.6,0-1c-0.6,0.4-1,1-1.2,1.8c-0.2,0-0.4,0.2-0.6,0.4c-0.6,0.4-1,1-1.4,1.6
		c0,0.2-0.2,0.4-0.4,0.4c-1,1-1.8,2-2.5,2.9c-1.4,1.6-2.9,2.7-4.1,4.5c0.6,0,1.2-0.6,1.8-0.8C465.5,181.7,465.7,181.5,465.9,181.5z
		 M465.7,185.4c-0.4,0-0.8,0.2-1,0.6C465.1,186,465.5,185.6,465.7,185.4z M477.4,172.9c0.2-0.2,0.2-0.4,0-0.6c0,0-0.4,0-0.4,0.2
		l-0.8,0.8c-2.3,3.1-5.1,6.1-7.8,9l-1,1.2c0,0.2-0.2,0.2-0.4,0.4c-0.4,0.2-0.6,0.4-0.8,1c0.4-0.2,0.8-0.4,1-0.8l0.4-0.4
		c0.8-0.4,1.2-0.8,1.8-1.4c2.7-2.7,5.3-5.9,7.8-9C477.4,173.1,477.4,173.1,477.4,172.9z M466.7,180.5v0.4c-0.2,0-0.2,0-0.4,0
		C466.5,180.9,466.5,180.7,466.7,180.5z M470.2,176.8v0.4c-0.2,0-0.4,0-0.4,0C469.8,177,470,176.8,470.2,176.8z M471,175.5
		c0,0.2,0,0.6,0,0.8h-0.4c0-0.2,0-0.4,0.2-0.6L471,175.5z M471.1,175.1h0.2c0,0.2-0.2,0.2-0.4,0.4C471,175.3,471,175.3,471.1,175.1z
		 M474.5,170.8h0.4c0-0.2,0.2-0.4,0.4-0.4C474.7,170,474.5,170,474.5,170.8z M475.6,176.8c0.2,0,0.4,0,0.4,0s-0.2,0.2-0.4,0.2V176.8
		z M481.3,167.5c1-1.2,2-2.3,2.7-3.7h-0.2l-0.6,0.6c0.2-0.2,0.2-0.2,0.4-0.2c-0.2,0.4-0.6,0.6-1,0.8l-0.4,0.6c0,0,0,0.2-0.2,0.4
		v-0.2l-0.6,0.6c-0.2,0.4-0.2,0.8-0.4,1l-0.8,0.8c0,0.2-0.2,0.2-0.2,0.2c-0.4,0.4-0.6,1-1,1.4v0.4c0.2,0.2,0.4,0,0.4,0
		C480.3,169.2,480.9,168.4,481.3,167.5z M479.5,195.8c0.4-0.6,0.8-1.2,0.8-2C479.7,194.4,479.7,195,479.5,195.8z M485,164l-4.5,6.2
		c-0.6,1-0.6,2-0.2,3.3c0.2,0.8,0.2,1.6,0.4,2.3c0,0.2-0.2,0.6,0.4,0.6c0.2-0.2,0.4-0.4,0.4-0.6C482.1,174.7,486.2,164.7,485,164z
		 M482.5,188v0.2C482.7,188.2,482.7,188,482.5,188z M483.8,163.8c0,0.2-0.2,0.2-0.2,0.4C483.6,164,483.6,164,483.8,163.8z
		 M484.4,157.9c0.4-0.2,1-0.4,1.2-1l0.4-0.6c0.6,0,1-0.4,1.2-0.8c0-0.2,0.2-0.4,0.4-0.6v0.4c0.2-0.2,0.2-0.4,0.4-0.6
		c0.6-0.2,1-0.6,1.2-1.2l0.4-0.6c-0.2,0-0.4,0.2-0.4,0.2c-0.2-0.2-0.2-0.4-0.2-0.6h0.4c0-0.2,0-0.4,0-0.4c-0.2,0-0.2,0.2-0.4,0.4
		c-0.8,0.4-1.4,1-1.8,1.8c-0.6,0.4-1.2,1-1.4,1.8c-0.2,0.2-0.4,0.4-0.4,0.6L484.4,157.9z M486.8,177.6c-0.6,0.6-0.8,1.2-1,2
		C486.4,179,486.6,178.4,486.8,177.6z M487.5,154.6c0.2,0.2,0.2,0.2,0.4,0.2c-0.2,0-0.2,0-0.4,0.2V154.6z M487.5,179v-0.2h0.2
		C487.5,178.8,487.5,179,487.5,179z M488.9,172.5c-0.4,0.4-0.4,0.8-0.4,1.2C488.7,173.3,488.7,172.9,488.9,172.5z M489.3,152.1
		c0.6-0.2,1-0.6,1.2-1.2c0.2,0,0.4-0.2,0.4-0.2l0.2-0.2c-0.2-0.2-0.2-0.2-0.4,0c0,0.2-0.2,0.2-0.2,0.4
		C489.9,151.3,489.7,151.7,489.3,152.1z M490.5,168.8c0,0.2-0.2,0.2-0.4,0.4c0,0.2,0,0.4,0.2,0.4l0.2-0.2V168.8z M495.5,193.4
		c-1.8,1.4-3.1,3.1-4.7,4.9c-0.4,0.2-0.4,0.6-0.6,1c1.8-1.4,3.3-3.3,4.9-5.1C495.3,194,495.5,193.8,495.5,193.4z M494.8,197.7
		c-0.2,0-0.2,0.2-0.4,0.4c-1.6,1-2.9,2.7-3.7,4.1c1.6-1.2,2.7-2.5,3.7-4.1C494.6,197.9,494.8,197.9,494.8,197.7z M491.8,165.7
		c-0.6,0.6-0.8,1.2-0.8,2C491.4,167.1,491.8,166.5,491.8,165.7z M493.2,166.9c-0.2-0.2-0.2-0.6-0.2-0.8h0.2V166.9z M495.2,197.1
		l-0.4,0.4C495,197.5,495.2,197.3,495.2,197.1z M495.5,196.7c-0.2,0.2-0.2,0.2-0.2,0.4C495.3,196.9,495.5,196.9,495.5,196.7z
		 M503,189.1c-0.4,0-0.4,0.2-0.6,0.4l-0.4,0.4c-0.2,0-0.2,0.2-0.4,0.4s-0.4,0.2-0.4,0.4l-0.4,0.4c-2,1.6-3.5,3.3-4.9,5.1
		c-0.2,0.2-0.2,0.4-0.2,0.6c2-1.6,3.7-3.3,5.1-5.5c0.2-0.2,0.4-0.2,0.4-0.4l0.4-0.4c0.2-0.2,0.4-0.4,0.6-0.6l0.4-0.4
		C502.8,189.5,502.8,189.3,503,189.1z M497.9,191.1c0.2,0,0.2,0,0.2-0.2L497.9,191.1z M498.7,190.3c1.4-0.6,2.3-1.6,2.9-2.9
		C500.4,188.2,499.4,189.1,498.7,190.3z M506.3,182.7c-1.4,1.2-2.7,2.3-3.7,3.7C504.1,185.6,505.5,184.4,506.3,182.7z M508,184.4
		c0-0.2,0.2-0.4,0.4-0.6v0.2C508.2,184.1,508.2,184.3,508,184.4z M508.4,183.9c1.2-1.4,2.3-2.5,3.7-3.7
		C511.4,181.7,510.2,183.1,508.4,183.9z M513.1,176.4c-1.8,0.8-2.9,2.1-4.1,3.5c0.8-0.2,1.4-0.6,2-1.2L513.1,176.4z M512.7,179.8
		c-0.2,0.2-0.4,0.2-0.4,0.4C512.3,180,512.5,179.8,512.7,179.8z M514.5,177.6c0,0,0.2,0,0.4,0c-0.6,1-1.2,1.6-2.1,2L514.5,177.6z
		 M514.9,177.6v-0.4c0,0,0.2,0,0.4,0C515.1,177.2,514.9,177.4,514.9,177.6z M515.3,177v-0.2c0.2,0,0.2,0,0.4,0
		C515.5,176.8,515.5,177,515.3,177z M515.6,176.3c0.2,0,0.2,0,0.4,0c-0.2,0.2-0.2,0.4-0.4,0.4V176.3z M518.8,170.8
		c-1,0.6-1.6,1.4-2.1,2C518,172.2,518,172.2,518.8,170.8z"
      />
      <path
        id="XMLID_282_"
        d="M610.1,169.2c-0.4,0.4-0.6-0.2-1.2-0.2c-0.2,0-0.2,0-0.4-0.2c-1.6-1.6-2.7-2-4.3-1.4
		c-1.2,0.4-2.1,0.6-3.1,1c-3.1,1.2-6.1,2.7-9,4.1c-1.6,0.8-2.9,1.8-4.3,2.7c-0.6,0.4-1.4,0.8-2.1,1.2c-0.4,0.2-1,0.2-1.2,0.8
		c-0.2,0.8-1.4,1.2-2.1,1.8l-0.4,0.4c0,0.2,0,0.2,0,0.2c-0.6,0.2-1.2,0.6-1.8,0.8v0.2c-0.2,0.2-0.4,0.2-0.4,0.4
		c0-0.2-0.2-0.2-0.2-0.4v-0.2c-0.4,0.2-1,0.4-1.2,1c-0.4,0.4-1,0.6-1.4,1c-0.4,0.4-1,0.6-1.4,1.2l-0.4,0.4c-0.6,0.2-1,0.6-1.4,1h0.2
		c-0.2,0.2-0.4,0.4-0.4,0.6c-0.4,0-0.6,0.2-1,0.4l-0.4,0.6c-0.8,0.2-1.4,0.8-1.8,1.4l-0.4,0.6c-1,0.4-1.8,1-2,2h-0.2
		c0.2,0.2,0.2,0.2,0.2,0.4c-0.2-0.2-0.2-0.2-0.2-0.4l-0.4,0.4c0,0.2-0.2,0.4-0.4,0.6c-0.6,0.2-1,0.4-1.2,1c-0.2,0.2-0.4,0.4-0.6,0.4
		c0,0.2-0.2,0.4-0.4,0.6c0,0.2-0.2,0.2-0.2,0.4c-1.6,0.8-2.5,2.1-3.5,3.5h1c-0.4,0.6-1,1.2-1.6,2c0-0.4,0-0.8-0.2-1
		c-0.6,0.4-1.2,1.2-1.6,2c-1.8,1.8-3.3,3.5-4.3,5.9l-0.4,0.4c-0.2,0.2-0.4,0.4-0.4,0.6c-0.2,0.2-0.4,0.2-0.4,0.2
		c-0.2,0.4,0,1.2-0.8,1.4c-0.8,0.2-1.6,0.4-2.5,0c-1.4-0.6-2.5-1-3.1-2.5c-0.2-0.6-0.8-1-1.2-1.4c-1-1-1.2-2-0.8-3.1
		c1.8-5.3,3.1-10.5,4.9-16c1.6-4.7,3.3-9.2,4.7-13.9c0.6-2.1,1.2-4.3,2.1-6.2l0.6-1.2c0.2-0.4,0.6-0.4,1-0.2c1,0.6,1.8,1.4,2.1,2.5
		c0.6,1.6,0.4,3.1,0.2,4.7c-0.6,4.1-2.3,7.8-2.3,11.7c0,0.6-0.4,1-0.4,1.4c0,0.4,0,0.6,0.4,1c0.4,0.4,0.8,0,1-0.2l2-1.8
		c3.1-3.1,6.6-5.3,10.1-7.8c2.7-2,5.5-3.3,8.4-5.1c3.7-2.1,7.6-4.1,11.7-6.1c2.5-1.2,5.5-2.1,8.4-2.5c2.7-0.4,5.1,0.4,7.2,2.5
		c1.2,1.2,2.3,2.3,3.1,3.7C610.7,168.4,610.5,168.8,610.1,169.2z M554.5,206.9h-0.4C554.1,206.7,554.3,206.7,554.5,206.9z
		 M599.8,167.3c-2.3,0.6-4.3,1.4-6.4,2.5c-7.4,4.1-14.4,8.2-20.7,13.5c-6.6,5.5-11.3,12.3-17.4,18.3c-0.4,0.4-1,1-1,1.8v0.2h0.2
		c1.2-0.8,1.8-2,2.5-2.9c5.3-6.4,10.9-12.7,17.8-18c7-5.5,14.4-11.3,23.2-14.2C598.6,168.3,599.4,167.9,599.8,167.3z M554.5,206.3
		c0.2,0,0.2,0,0.4,0c0,0.2-0.2,0.4-0.4,0.6V206.3z M554.9,206.3c0-0.2,0-0.2,0-0.4h0.4L554.9,206.3z M555.3,205.9
		c0.2-0.2,0.2-0.4,0.4-0.4C555.7,205.7,555.5,205.7,555.3,205.9z M555.8,205.1c0,0.2-0.2,0.4-0.2,0.4
		C555.7,205.3,555.8,205.3,555.8,205.1z M556.2,204.9c0,0-0.2,0.2-0.4,0.2c0.2,0,0.2-0.2,0.4-0.4l0.4-0.4L556.2,204.9z M557,204
		l-0.4,0.4c0-0.2,0.2-0.2,0.2-0.4l0.4-0.4C557.2,203.8,557.2,203.8,557,204z M557.2,203.6l0.2-0.2
		C557.4,203.4,557.4,203.6,557.2,203.6z M557.4,203.4c0-0.2,0.2-0.2,0.2-0.4l0.6-0.6c-0.2,0.2-0.4,0.4-0.4,0.6
		C557.6,203.2,557.6,203.4,557.4,203.4z M558.2,202.4l0.4-0.6h0.2l-0.2,0.2C558.4,202,558.4,202.2,558.2,202.4z M560.1,188.7
		c0.6-0.2,1-0.4,1.2-0.8C560.9,188,560.5,188.4,560.1,188.7z M560.5,199.5l0.4-0.4C560.9,199.3,560.7,199.3,560.5,199.5z
		 M562.7,197.1h-0.2c0.2-0.2,0.4-0.4,0.4-0.6C562.9,196.7,562.7,196.9,562.7,197.1z M563.5,196.2l-0.6,0.4c0.2-0.2,0.4-0.4,0.4-0.4
		l0.4-0.6c0.2-0.2,0.4-0.4,0.4-0.4l0.6-0.6c-0.2,0.2-0.4,0.4-0.4,0.6c-0.2,0.2-0.4,0.2-0.4,0.4C563.7,195.8,563.5,196,563.5,196.2z
		 M563.1,185.8c0.6-0.2,1-0.4,1.2-1C563.8,185,563.5,185.4,563.1,185.8z M564.6,194.6c0-0.2,0.2-0.4,0.4-0.4
		C564.8,194.4,564.6,194.4,564.6,194.6z M564.8,184.3h1c0-0.2,0.2-0.6,0.2-0.8C565.4,183.5,565,183.9,564.8,184.3z M565,193.6
		c0,0,0.2,0,0.4,0c-0.2,0.2-0.2,0.4-0.4,0.4V193.6z M565.4,193.2c0,0,0.2,0,0.4,0l-0.4,0.4V193.2z M565.8,192.6c0,0,0.2,0,0.4,0
		l-0.4,0.6V192.6z M566.2,183.3c0,0,0.2-0.2,0.4-0.2c0.2-0.2,0.6-0.2,0.8-0.2c0,0.2,0.2,0.4,0.2,0.4c0.6,0,1-0.4,1.2-0.8h-1
		c0.2-0.4,0.2-0.8,0.4-1h1c0,0,0,0.2,0,0.4l1-0.4c-0.4,0-0.6,0-1,0c0-0.4,0.2-0.8,0.4-1h1c0,0,0,0.2,0,0.4c0.2-0.2,0.6-0.4,0.8-0.4
		h-0.8c0-0.4,0.2-0.6,0.4-0.8c0.2-0.2,0.6-0.2,0.8-0.2c0,0,0.2,0.2,0.2,0.4c0.4,0,0.6-0.2,0.8-0.4h-1c0.2-0.4,0.4-0.6,0.6-1h0.8
		c0,0,0,0.2,0,0.4c0.4,0,0.8-0.2,1-0.4c0.6-0.2,1-0.6,1.2-1h-1c0.2-0.2,0.2-0.2,0.2-0.4l0.8-0.2c0,0.2,0,0.4,0.2,0.6
		c0.2-0.2,0.6-0.2,0.8-0.6c0.6-0.2,1-0.4,1.4-1c0.4,0,0.6-0.2,0.8-0.4c1-0.4,1.8-0.8,2.1-1.6v-0.4c0,0.2,0,0.2,0.2,0.4
		c0.2,0,0.6-0.2,0.8-0.4c0.4-0.2,0.8-0.2,0.8-0.6h-0.8c0.2-0.2,0.6-0.2,0.8-0.4c0,0.2,0,0.2,0.2,0.4c0.2,0,0.6-0.2,0.8-0.4l-0.8-0.2
		c0.2,0,0.4-0.2,0.8-0.4v0.4c1-0.2,1.8-0.6,2.1-1.4l1-0.4c0.4-0.2,0.6-0.2,0.8-0.6c0.4,0,0.8-0.2,1-0.4c0.6-0.2,0.8-0.8,1.4-1.2v0.2
		l0.8-0.6c0-0.2,0-0.2,0-0.4c0.4,0,0.6,0,1-0.2v-0.4c1,0.4,1.6-0.2,2.1-0.6v-0.4c0.4,0,0.6,0,1,0c0-0.2,0-0.4-0.2-0.4
		s-0.6,0-0.8,0.4c-1,0-1.8,0.2-2.3,1c-0.4,0-0.8,0-1,0.4c-3.5,1.4-6.6,3.3-9.8,5.3c-1.6,0.6-2.9,1.6-3.9,2.5c-1,0.2-1.8,0.8-2.3,1.4
		c-0.8,0.4-1.6,1-2.1,1.6c-0.4,0.2-1,0.6-1.4,1c-0.4,0.2-1,0.4-1.2,1.2c-0.6,0-1,0.4-1.2,1c-0.8,0.2-1.4,0.6-1.8,1.4
		C566.4,183.1,566.2,183.1,566.2,183.3z M566.2,192.3c0.2,0,0.6,0,0.8,0c-0.2,0.2-0.4,0.2-0.8,0.4V192.3z M567,192.3
		c0.2-0.2,0.4-0.4,0.6-0.6c-0.2,0.2-0.4,0.4-0.4,0.6H567z M567.4,191.3h0.6c-0.2,0.2-0.4,0.2-0.4,0.4
		C567.6,191.5,567.4,191.5,567.4,191.3z M567.8,190.7h0.6c-0.2,0.2-0.2,0.4-0.4,0.6C567.9,191.1,567.9,190.9,567.8,190.7z
		 M569.5,189.9l0.4-0.6c0,0.2-0.2,0.4-0.2,0.6H569.5z M570.5,188.7c0-0.2-0.2-0.2-0.2-0.4h0.4C570.7,188.5,570.5,188.5,570.5,188.7z
		 M570.7,187.8h1c-0.4,0.2-0.6,0.4-0.8,0.6C570.9,188.2,570.7,188,570.7,187.8z M572,187.4c0,0.2-0.2,0.2-0.4,0.4
		C571.9,187.6,571.9,187.4,572,187.4z M572,187.4l0.4-0.6C572.4,187,572.2,187.2,572,187.4z M572.4,186.4c0.2,0,0.2,0,0.4,0
		l-0.4,0.4V186.4z M572.8,185.8c0.4,0,0.6,0,1,0l-1,0.6c0,0,0,0,0-0.2V185.8z M572.8,186.4h0.2H572.8z M574,178v0.6
		c-0.4,0-0.6,0-1,0C573.2,178.4,573.6,178.2,574,178z M573.8,185.4c0,0,0.2,0,0.4,0c-0.2,0-0.2,0.2-0.4,0.4V185.4z M574.2,184.8
		c0.2,0,0.6,0,0.8,0l-0.8,0.6V184.8z M575,184.8c0.2-0.2,0.4-0.4,0.6-0.6v0.2c-0.2,0.2-0.4,0.2-0.4,0.4H575z M576.1,176.4
		c0,0.2,0,0.4,0,0.6h-1L576.1,176.4z M575.6,184.3c0,0-0.2-0.2-0.2-0.4h0.6L575.6,184.3z M577.3,175.7c0,0,0.2,0.2,0.2,0.4h-1
		c0,0,0-0.2,0-0.4H577.3z M578.3,175.1c0,0.2,0,0.4,0,0.6h-1L578.3,175.1z M578.5,181.3v0.6C578.5,181.5,578.3,181.5,578.5,181.3z
		 M578.7,182.1l-0.2-0.2C578.5,181.9,578.7,181.9,578.7,182.1z M581.2,173.1c0.2,0.2,0.2,0.4,0.2,0.6c-0.2-0.2-0.6-0.2-0.8-0.2v-0.2
		C580.8,173.3,581,173.3,581.2,173.1z M594.3,165.7c0.8,0,1.4-0.4,1.2-0.4C594.9,165.3,594.5,165.5,594.3,165.7z"
      />
      <path
        id="XMLID_330_"
        d="M677.4,172l-3.5,3.3c-0.4,0.4-0.8,0.8-1.6,1v-0.4c0,0.2-0.2,0.4-0.4,0.4l-0.4,0.6c-0.2,0-0.4,0.2-0.4,0.4
		c-0.2,0-0.4,0.2-0.4,0.4l-1.2,0.6c-2.7,2.9-5.7,5.7-8.6,8.6c-0.2,0.2-0.4,0.6-0.6,1c-0.2,0-0.4,0.2-0.4,0.4
		c-0.2,0.2-0.4,0.2-0.4,0.4c0.2,0,0.2-0.2,0.4-0.4c0.2,0,0.2-0.2,0.4-0.4c0.8-0.2,1.2-1,1.8-1.4c0.6-0.8,1.2-1.6,2.1-2
		c-0.2,0.8-0.8,1.2-1.4,1.6c-3.1,3.5-6.4,7-9.8,10.5c-2,2.3-3.9,4.5-6,6.6c-2.9,3.5-6.2,6.6-9.6,9.6c-0.8,0.8-1.8,1.4-2.7,2
		c-1.8,0.8-3.5,0.8-5.1-0.6l-1-0.6c-1.2-1-1.8-2.1-1.8-3.9c0-1.6,0.2-2.9,0.6-4.5c1.4-6.1,3.7-11.9,5.9-17.8
		c0.6-2.1,1.4-4.1,2.1-6.2c0.2-0.4,0.4-1,0.4-1.4c0.2-0.4,0-0.6-0.2-0.8c-0.8-0.2-0.8-0.8-0.8-1.6v-2.3c0-0.6-0.6-0.6-1-0.6v0.2
		c-0.2,0-0.2,0.2-0.4,0.4v-0.4c-0.2,0-0.4,0.2-0.4,0.4h-0.2c-0.2,0.4-0.4,0.8-0.8,1.2c0,0.2-0.2,0.4-0.4,0.4l-0.4,0.6
		c0.2,0,0.2,0,0.4,0c-0.2,0-0.2,0.2-0.4,0.4c-4.5,5.5-9.8,10.1-16,13.7c-1.4,0.8-2.9,1.4-4.5,1.8c-1.2,0.2-2.1,0.2-3.3-0.2
		c-2.3-0.8-3.7-2.5-4.7-4.7c-0.4-0.6-0.4-1.4-0.2-2.1c0.2-1.8,0.6-3.1,1.2-4.5c0.8-1.6,1.6-2.9,2.3-4.1c2.3-3.1,5.1-6.1,8.2-8.2
		c3.5-2.3,7-3.7,11.3-3.5c1.2,0,2.3,0.4,3.5,0.8c1.2,0.4,1.4,0.4,2.3-0.8c1.4-2.1,2.9-4.1,4.3-6.2l0.8,0.4v-0.2
		c3.1-3.3,5.9-7.2,9.2-10.3c0.6-0.8,1.4-1.6,2.1-2.1c1-0.8,1.6-0.8,2.5-0.2c0.6,0.4,1,0.6,1.4,1c1.8,1.2,2.1,2.5,1.6,4.5
		c-1,2.7-1.8,5.5-3.1,7.8c-0.4,0.6-0.6,1-0.8,1.6l0.6-0.8c0,0.6-0.2,1.2-0.8,1.8V162c-0.2,1-0.6,1.8-1,2.7l-0.2,0.8l1,0.6
		c0,0.2-0.2,0.6-0.4,0.8c-0.2,0.6-0.2,1-0.4,1.4c-0.8,1-1.6,2.3-1.6,3.7c-0.8,1-1.4,2-1.4,3.1c-0.2,0.2-0.4,0.4-0.4,0.8v-0.2
		c-0.6,1-1,2-1.4,3.1c-0.4,0.6-0.8,1.4-0.8,2.1c-0.4,0.4-0.4,0.8-0.6,1.4h0.2c-0.6,0.6-0.8,1.4-1,2.1c-0.2,0.2-0.2,0.6-0.4,0.8
		c-0.6,0.4-0.8,1-1,1.6l-2.9,8c-0.4,0.8-0.8,1.8-0.8,2.7c-1.4,2.5-2.1,5.1-2.5,7.8c-0.6,1-0.8,2.1-0.8,3.3h-0.2c1,0.8,1,0.8,1.4,0.4
		l1.8-1.4c3.1-3.3,6.4-6.8,9.8-10.1c2.9-2.9,5.9-5.9,8.8-8.6c3.1-3.3,6.4-6.4,9.8-9.6c3.1-2.9,6.1-6.1,9.4-8.8
		c0.4-0.2,0.6-0.4,0.8-0.6c1.2-1,1.4-1,2.5,0C678.2,170.8,678.2,171.2,677.4,172z M623.4,176.4l3.7-4.7c0.6-0.6,1-1.4,1.6-2.1
		c0.2-0.2,0-0.6-0.4-0.8h-1c-1.6,0-3.3,0.4-4.9,1.2c-1.8,0.8-3.5,2-5.1,3.1c-0.6,0.4-1.2,1-2,1c0.4-0.8,1.4-1.2,1.8-2
		c0.6-0.2,1-0.4,1.2-1c-0.6,0.2-1,0.4-1.4,0.8c-2.1,1.4-4.1,3.1-5.5,5.7c-0.6,0.2-1,1-1.2,1.6c0.6-0.2,1-0.8,1.2-1.6
		c0.8-0.4,1.2-1,1.6-1.4c0.6-0.6,1.2-1.4,2-1.8c0,0.8-0.6,1.4-1,2c-1.8,1.4-2.9,3.1-4.1,5.1c-1.2,1.8-1.6,3.9-2,5.9
		c-0.2,0.8,0.4,1.2,1.2,1c1.2-0.6,2.5-1.2,3.7-2.1C616.7,183.3,620.3,180,623.4,176.4z M609.3,180.4c0.6-0.2,0.8-0.6,1-1
		C609.9,179.6,609.7,180,609.3,180.4z M609.9,175.5h0.4c0-0.2,0-0.2,0-0.4h0.4c0,0.2,0,0.2,0,0.4l0.4-0.4c0.2-0.2,0.2-0.4,0.4-0.4
		h-0.4c0,0,0,0.2,0,0.4h-0.4c0-0.2,0-0.4,0-0.4c-0.2,0-0.2,0.2-0.4,0.4C610.1,175.1,609.9,175.3,609.9,175.5z M610.7,174.5h0.4v-0.2
		C610.9,174.3,610.9,174.5,610.7,174.5z M611.1,174.1h0.4c0,0.2,0,0.4,0,0.4c0.2,0,0.4-0.2,0.4-0.4h-0.4c0,0,0-0.2,0-0.4
		C611.5,173.9,611.3,174.1,611.1,174.1z M611.7,173.7h0.2c0-0.2,0-0.2,0-0.4C611.9,173.5,611.7,173.5,611.7,173.7z M612.1,174.1
		c0,0,0.2-0.2,0.4-0.4c1-0.8,2.1-1.6,2.5-2.9c0.2,0,0.2-0.2,0.4-0.4c0.4,0,0.6-0.2,0.8-0.4c-0.2,0-0.6,0-0.8,0.4l-0.6,0.4
		c-1.2,0.4-2.1,1.4-2.7,2.5c0,0.2,0.2,0.2,0.4,0.4h-0.4V174.1z M622.2,181.5v0.2c0.2,0,0.2-0.2,0.4-0.2H622.2c0-0.2,0.2-0.4,0.4-0.6
		v0.4c0.2,0,0.2-0.2,0.4-0.4s0.4-0.2,0.4-0.4H623c0.2-0.2,0.4-0.2,0.4-0.4c0,0.2,0,0.2,0.2,0.4c0-0.2,0.2-0.2,0.2-0.4h-0.4
		c0.2-0.2,0.4-0.4,0.6-0.4v0.4c0-0.2,0.2-0.4,0.4-0.4c0.8-0.6,1.4-1.2,1.6-2.1h-0.2h-0.2c0.2-0.2,0.4-0.4,0.6-0.4v0.4
		c0.2,0,0.2-0.2,0.4-0.4l0.4-0.4c-0.2,0-0.4,0-0.4,0c0-0.2,0.2-0.4,0.4-0.6v0.4c0.2,0,0.2-0.2,0.4-0.4c0.4-0.2,0.8-0.8,0.8-1.2
		l0.4-0.4c0,0-0.2,0-0.4,0v0.4c0,0-0.2,0-0.4,0c0.2-0.2,0.4-0.4,0.4-0.6c0.2,0,0.4-0.2,0.6-0.4v0.4c0,0,0.2-0.2,0.4-0.4h-0.4
		c0-0.2,0-0.4,0-0.8c0.2,0,0.2-0.2,0.4-0.4h0.4c0,0.2,0,0.6,0,0.8c0.4-0.4,0.8-0.8,0.8-1.2h-0.4c0.2-0.2,0.2-0.4,0.4-0.4v0.2
		c0.2,0,0.4-0.2,0.6-0.2h-0.6c0-0.4,0-0.6,0-1c-0.6,0.4-1,1-1.2,1.8c-0.2,0-0.4,0.2-0.6,0.4c-0.6,0.4-1,1-1.4,1.6
		c0,0.2-0.2,0.4-0.4,0.4c-1,1-1.8,2-2.5,2.9c-1.4,1.6-2.9,2.7-4.1,4.5c0.6,0,1.2-0.6,1.8-0.8C621.8,181.7,622,181.5,622.2,181.5z
		 M622,185.4c-0.4,0-0.8,0.2-1,0.6C621.4,186,621.8,185.6,622,185.4z M633.7,172.9c0.2-0.2,0.2-0.4,0-0.6c0,0-0.4,0-0.4,0.2
		l-0.8,0.8c-2.3,3.1-5.1,6.1-7.8,9l-1,1.2c0,0.2-0.2,0.2-0.4,0.4c-0.4,0.2-0.6,0.4-0.8,1c0.4-0.2,0.8-0.4,1-0.8l0.4-0.4
		c0.8-0.4,1.2-0.8,1.8-1.4c2.7-2.7,5.3-5.9,7.8-9C633.7,173.1,633.7,173.1,633.7,172.9z M623,180.5v0.4c-0.2,0-0.2,0-0.4,0
		C622.8,180.9,622.8,180.7,623,180.5z M626.5,176.8v0.4c-0.2,0-0.4,0-0.4,0C626.1,177,626.3,176.8,626.5,176.8z M627.3,175.5
		c0,0.2,0,0.6,0,0.8h-0.4c0-0.2,0-0.4,0.2-0.6L627.3,175.5z M627.5,175.1h0.2c0,0.2-0.2,0.2-0.4,0.4
		C627.3,175.3,627.3,175.3,627.5,175.1z M630.8,170.8h0.4c0-0.2,0.2-0.4,0.4-0.4C631,170,630.8,170,630.8,170.8z M632,176.8
		c0.2,0,0.4,0,0.4,0s-0.2,0.2-0.4,0.2V176.8z M637.6,167.5c1-1.2,2-2.3,2.7-3.7h-0.2l-0.6,0.6c0.2-0.2,0.2-0.2,0.4-0.2
		c-0.2,0.4-0.6,0.6-1,0.8l-0.4,0.6c0,0,0,0.2-0.2,0.4v-0.2l-0.6,0.6c-0.2,0.4-0.2,0.8-0.4,1l-0.8,0.8c0,0.2-0.2,0.2-0.2,0.2
		c-0.4,0.4-0.6,1-1,1.4v0.4c0.2,0.2,0.4,0,0.4,0C636.6,169.2,637.2,168.4,637.6,167.5z M635.9,195.8c0.4-0.6,0.8-1.2,0.8-2
		C636.1,194.4,636.1,195,635.9,195.8z M641.3,164l-4.5,6.2c-0.6,1-0.6,2-0.2,3.3c0.2,0.8,0.2,1.6,0.4,2.3c0,0.2-0.2,0.6,0.4,0.6
		c0.2-0.2,0.4-0.4,0.4-0.6C638.4,174.7,642.5,164.7,641.3,164z M638.8,188v0.2C639,188.2,639,188,638.8,188z M640.2,163.8
		c0,0.2-0.2,0.2-0.2,0.4C640,164,640,164,640.2,163.8z M640.7,157.9c0.4-0.2,1-0.4,1.2-1l0.4-0.6c0.6,0,1-0.4,1.2-0.8
		c0-0.2,0.2-0.4,0.4-0.6v0.4c0.2-0.2,0.2-0.4,0.4-0.6c0.6-0.2,1-0.6,1.2-1.2l0.4-0.6c-0.2,0-0.4,0.2-0.4,0.2
		c-0.2-0.2-0.2-0.4-0.2-0.6h0.4c0-0.2,0-0.4,0-0.4c-0.2,0-0.2,0.2-0.4,0.4c-0.8,0.4-1.4,1-1.8,1.8c-0.6,0.4-1.2,1-1.4,1.8
		c-0.2,0.2-0.4,0.4-0.4,0.6L640.7,157.9z M643.1,177.6c-0.6,0.6-0.8,1.2-1,2C642.7,179,642.9,178.4,643.1,177.6z M643.9,154.6
		c0.2,0.2,0.2,0.2,0.4,0.2c-0.2,0-0.2,0-0.4,0.2V154.6z M643.9,179v-0.2h0.2C643.9,178.8,643.9,179,643.9,179z M645.2,172.5
		c-0.4,0.4-0.4,0.8-0.4,1.2C645,173.3,645,172.9,645.2,172.5z M645.6,152.1c0.6-0.2,1-0.6,1.2-1.2c0.2,0,0.4-0.2,0.4-0.2l0.2-0.2
		c-0.2-0.2-0.2-0.2-0.4,0c0,0.2-0.2,0.2-0.2,0.4C646.2,151.3,646,151.7,645.6,152.1z M646.8,168.8c0,0.2-0.2,0.2-0.4,0.4
		c0,0.2,0,0.4,0.2,0.4l0.2-0.2V168.8z M651.9,193.4c-1.8,1.4-3.1,3.1-4.7,4.9c-0.4,0.2-0.4,0.6-0.6,1c1.8-1.4,3.3-3.3,4.9-5.1
		C651.7,194,651.9,193.8,651.9,193.4z M651.1,197.7c-0.2,0-0.2,0.2-0.4,0.4c-1.6,1-2.9,2.7-3.7,4.1c1.6-1.2,2.7-2.5,3.7-4.1
		C650.9,197.9,651.1,197.9,651.1,197.7z M648.2,165.7c-0.6,0.6-0.8,1.2-0.8,2C647.8,167.1,648.2,166.5,648.2,165.7z M649.5,166.9
		c-0.2-0.2-0.2-0.6-0.2-0.8h0.2V166.9z M651.5,197.1l-0.4,0.4C651.3,197.5,651.5,197.3,651.5,197.1z M651.9,196.7
		c-0.2,0.2-0.2,0.2-0.2,0.4C651.7,196.9,651.9,196.9,651.9,196.7z M659.3,189.1c-0.4,0-0.4,0.2-0.6,0.4l-0.4,0.4
		c-0.2,0-0.2,0.2-0.4,0.4s-0.4,0.2-0.4,0.4l-0.4,0.4c-2,1.6-3.5,3.3-4.9,5.1c-0.2,0.2-0.2,0.4-0.2,0.6c2-1.6,3.7-3.3,5.1-5.5
		c0.2-0.2,0.4-0.2,0.4-0.4l0.4-0.4c0.2-0.2,0.4-0.4,0.6-0.6l0.4-0.4C659.1,189.5,659.1,189.3,659.3,189.1z M654.2,191.1
		c0.2,0,0.2,0,0.2-0.2L654.2,191.1z M655,190.3c1.4-0.6,2.3-1.6,2.9-2.9C656.7,188.2,655.8,189.1,655,190.3z M662.6,182.7
		c-1.4,1.2-2.7,2.3-3.7,3.7C660.5,185.6,661.8,184.4,662.6,182.7z M664.4,184.4c0-0.2,0.2-0.4,0.4-0.6v0.2
		C664.6,184.1,664.6,184.3,664.4,184.4z M664.8,183.9c1.2-1.4,2.3-2.5,3.7-3.7C667.7,181.7,666.5,183.1,664.8,183.9z M669.4,176.4
		c-1.8,0.8-2.9,2.1-4.1,3.5c0.8-0.2,1.4-0.6,2-1.2L669.4,176.4z M669,179.8c-0.2,0.2-0.4,0.2-0.4,0.4
		C668.7,180,668.8,179.8,669,179.8z M670.8,177.6c0,0,0.2,0,0.4,0c-0.6,1-1.2,1.6-2.1,2L670.8,177.6z M671.2,177.6v-0.4
		c0,0,0.2,0,0.4,0C671.4,177.2,671.2,177.4,671.2,177.6z M671.6,177v-0.2c0.2,0,0.2,0,0.4,0C671.8,176.8,671.8,177,671.6,177z
		 M672,176.3c0.2,0,0.2,0,0.4,0c-0.2,0.2-0.2,0.4-0.4,0.4V176.3z M675.1,170.8c-1,0.6-1.6,1.4-2.1,2
		C674.3,172.2,674.3,172.2,675.1,170.8z"
      />
      <path
        id="XMLID_379_"
        d="M742.2,177.4c-0.6,0.2-1.4,0.4-2,0.8c-3.5,1.8-6.8,3.7-10.1,5.7c-3.1,1.6-6.1,3.9-9,5.9
		c-4.5,3.1-8.8,5.9-13.5,8.4c-2.1,1.4-4.5,1.8-7,1.6c-1.6,0-2.9-0.4-4.1-1.6l-2-2c-1.8-2-2.7-3.9-2-6.4v-1c0-0.6-0.6-1-1-0.6
		c-0.4,0.2-1,0.6-1.4,1c-2.3,1.8-4.7,3.7-7.2,5.1c-2.1,1.4-4.5,2.7-7,3.5c-1,0.2-2,0.4-2.9,0.4c-2.5,0-4.9-0.6-6.4-2.5
		c-0.6-0.4-1-1.2-1.6-1.6c-1.8-2.1-2-4.3-1.4-7c0.6-2.1,1.6-4.3,2.7-6.1c2.7-4.9,5.9-9.8,9.6-13.9l0.6-0.8c0.4-0.4,0.8-0.8,1.2-0.6
		c0.8,0,1.6,0.6,1.8,1.4c0.2,1.2,0,2-0.4,2.9c-0.8,2.3-2.1,4.5-3.3,6.6c-0.8,1.2-1.4,2.3-2,3.5c-0.2,1-0.4,1.8-1,2.3
		c-0.8,1-1.2,2-1.4,2.9c-0.2,1.4-0.6,2.5-0.8,3.7l-0.4,1.4c-0.2,1.4,0.2,1.8,1.4,1.2c1-0.2,2-0.8,3.1-1.4c3.9-2.7,8-5.7,11.9-8.6
		c2.9-2,5.7-3.9,8.6-5.7l1.2-0.8c1.2-0.6,2.1-0.4,3.1,0.4c1.4,1,2.9,2.1,3.7,3.7c0.6,1,0.8,2,0.4,2.9c-0.2,0.4-0.4,0.8-0.4,1.4
		c-1,2.3-2.3,4.3-2.9,6.6c0,0.6-0.2,1-0.4,1.4c0,0.4-0.2,1,0.2,1.2c0.4,0.4,1,0,1.6,0c1.2-0.6,2.5-1.2,3.7-2c3.3-1.8,6.8-3.5,10-5.5
		c1-0.2,2-0.8,2.7-1.4c2.9-1.6,5.9-3.1,9-4.7c2.9-1.4,5.9-2.9,8.6-4.3l3.1-1.2c1.6-0.2,3.3,1,3.7,2.1
		C742.8,176.6,742.8,177.2,742.2,177.4z M681.9,188.4c-0.4,0.2-0.8,0.4-1,0.8C681.3,189.1,681.7,188.7,681.9,188.4z M696.4,178.2
		c-0.2,0-0.4-0.2-0.4-0.2l-0.4,0.4c-0.6,0.2-1,0.4-1.4,0.8c-1.4,0.8-2.9,2-4.1,3.1c-1.2,0.4-2.3,1.2-3.1,2c-1.2,1-2.3,1.6-3.3,2.7
		c-0.4,0.2-1,0.4-1.2,0.8c0.6,0,1-0.2,1.4-0.8c0.2-0.2,0.8-0.4,1.2-0.4c0.2-0.4,0.8-0.8,1.4-1c0,0,0-0.2,0.2-0.4v0.4
		c0.4,0,0.6-0.2,1-0.4h-1c0.2,0,0.4-0.4,0.8-0.6c0.2,0,0.4-0.2,0.6-0.2v0.4c0.6,0,1-0.4,1.2-0.8h-0.8c0.4-0.2,0.8-0.2,0.8-0.4v0.2
		c0.2,0,0.4,0,0.6-0.2l0.6-0.4c0,0-0.4,0-0.6,0c0.2-0.4,0.4-0.6,0.6-0.8c0,0,0.4,0,0.6-0.2v0.4c0.6-0.2,1-0.4,1.4-0.8
		c0.2-0.2,0.2-0.4,0.4-0.4h-0.4c0,0,0,0.2,0,0.4h-0.8c0.2-0.2,0.4-0.4,0.8-0.6c0.2,0,0.2,0,0.4-0.2v0.2c0.2,0,0.4,0,0.8-0.2
		c0.2-0.2,0.4-0.4,0.6-0.4h-0.6c0,0,0,0.2,0,0.4h-0.8c0.2-0.2,0.6-0.4,0.8-0.6c0.2,0,0.4-0.2,0.6-0.4v0.4c0.2,0,0.4-0.2,0.6-0.4H694
		c0.2-0.2,0.2-0.4,0.4-0.6c0.4,0,0.6-0.2,0.8-0.2l0.6-0.8c0.2,0,0.4,0,0.6-0.2V178.2z M693.4,184.6c0-0.2-0.4-0.2-0.4-0.2l-0.6,0.6
		c-2.5,1.6-5.1,3.5-7.4,5.5c-0.6,0.2-1,0.8-1.2,1.2c0.6,0,1.2-0.4,1.6-0.8c2.3-1.8,4.9-3.5,7.4-5.5c0-0.2,0.2-0.2,0.4-0.4
		S693.4,184.6,693.4,184.6z M686.6,185.8h-0.2c0,0,0,0.2,0,0.4C686.4,186,686.4,185.8,686.6,185.8z M689.1,183.7
		c0.2-0.2,0.4-0.4,0.6-0.4v0.4H689.1z M716.1,191.3c-0.6,0.2-1,0.4-1.2,0.8C715.3,191.9,715.7,191.7,716.1,191.3z M716.3,187.6
		c0.2-0.4,0.8-0.6,1-1C716.9,186.8,716.5,187,716.3,187.6z M720.6,188.4c-1.4,0.4-2.9,1.6-4.3,2.9
		C717.8,190.5,719.4,189.7,720.6,188.4z M719,185.8c0.6-0.2,1-0.4,1.2-0.8C719.8,185.2,719.2,185.2,719,185.8z M729.5,182.7
		c-1.2,0.2-2,1-2.9,1.8c-1.4,0.4-2.5,1.2-3.5,2c-0.4,0.2-0.8,0.4-1.2,1c-0.4,0.2-1,0.4-1.2,1c0.6-0.2,1-0.4,1.4-0.8
		c0.6-0.2,0.8-0.4,1.2-1c1.2-0.4,2.5-1,3.3-2.1C728,184.3,728.8,183.9,729.5,182.7z M725.3,182.3l0.4-0.4
		C725.4,182.1,725.3,182.1,725.3,182.3z M725.8,181.9c0.4-0.2,0.6-0.2,0.8-0.4C726.4,181.5,726,181.7,725.8,181.9z M727.2,181.1
		c0.4-0.2,0.6-0.2,0.8-0.4C727.8,180.7,727.4,180.9,727.2,181.1z M728,180.5c0.2,0,0.6-0.2,0.6-0.4
		C728.4,180.4,728.2,180.5,728,180.5z M732.5,181.1c-0.6,0.2-1.6,0.4-2,1.2C731.3,182.1,732.1,181.7,732.5,181.1z M733.4,180.7
		c-0.4,0-0.6,0-0.8,0.4C733.1,181.1,733.1,180.9,733.4,180.7z M734.4,180.2c-0.4,0.2-0.8,0.2-1,0.4
		C733.8,180.5,734,180.4,734.4,180.2z M735.8,179.6c0-0.2-0.2-0.2-0.4,0c0,0,0,0.2-0.2,0.4c0.2-0.2,0.4-0.2,0.4-0.2L735.8,179.6z"
      />
      <path
        id="XMLID_399_"
        d="M797.1,170.6c0,0.6,0,1.2-0.6,1.4l-2,0.6c-4.7,0.8-9.4,1.6-14.2,2.3c-4.5,0.8-9.2,1.4-13.7,2.1
		c-1.4,0.2-2.5,0.4-3.5,1c-1.8,0.8-2.9,2-3.1,4.1c-0.4,2.7-1,5.5-1.4,8.2l-2.7,17.4c-0.2,1.8-0.8,3.7-1.2,5.5
		c-0.4,1.2-0.6,2.1-0.8,3.3c-0.6,3.1-1.4,6.2-2.5,9.2l-0.6,1.6c-0.2,2.3-1,4.5-2,6.4c-1.4,3.3-2.7,6.4-3.7,9.8c-0.6,1-1,2-1.2,2.9
		c-1.6,2.9-2.9,6.1-4.1,9.4c0,0.2-0.2,0.6-0.4,1c-0.4,0.4-0.6,1-0.8,1.4c-1,1.8-2,3.3-2.7,5.3c-1,2.3-2.3,4.3-3.5,6.4v0.6
		c-2,2.1-3.1,4.7-4.7,7c-3.9,5.5-8.2,10.5-13.3,15.2c-1.4,1.4-2.9,2.3-4.7,3.3c-3.1,1.8-6.8,0.4-9.4-1.6c-0.8-0.6-1-1.2-1.2-2
		c-0.6-1.4-0.6-2.7-0.6-4.1c0.4-4.1,0.8-8.2,1.8-12.1c1.2-5.7,2.5-11.3,4.3-17c1.6-5.9,3.5-11.5,5.7-17.4c1.6-4.1,2.9-8.2,4.5-12.3
		c2.3-6.1,4.7-12.1,7.2-18.2c2.3-6.1,4.9-12.1,7.4-18l7-15.8c2.5-5.7,5.3-11.5,8-17.2c3.1-6.4,6.2-12.9,9.6-19.3
		c2.3-4.7,4.7-9.4,7.2-14.1c2.1-3.7,3.9-7.4,6.1-11.1c1-2,2.1-3.9,3.3-5.7c2.5-4.5,5.1-8.8,7.8-13.1c2.5-3.9,5.1-7.8,8-11.5l1.6-1.8
		c0.8-0.6,1.6-0.2,2.1,0.6c0.4,0.2,0.4,0.6,0.2,0.8c-2.1,1.8-2.9,4.3-4.1,6.6c-0.6,1-1,2-1.8,2.9c-1.8,2.7-2.9,5.7-4.5,8.6
		l-5.3,10.1c-1.2,2.1-2.1,4.3-3.3,6.2l-7.6,15.2c-1.8,3.5-3.5,7-5.1,10.9c-0.6,1.6-1.4,2.9-2.1,4.3c-2.1,4.3-4.3,8.6-6.1,13.1
		c-0.2,1-0.8,2-1.4,2.7c-2,4.1-3.5,8.2-5.3,12.3c-0.4,0.6-0.8,1.2-1,2l-3.5,8.6c-0.2,0.6-0.8,1.2-1,1.8l-1.2,2.9h0.2
		c-0.6,1-1.2,2-1.6,3.1c-0.8,3.1-2.3,6-3.5,9c-2,4.7-3.7,9.4-5.5,14.2c-0.6,1-1,2-1.4,3.1c0,0.2,0,0.6-0.2,0.8
		c-2,3.3-2.7,7-3.9,10.5l-1.8,3.7c0,0.2-0.2,0.4-0.2,0.6c-0.8,3.5-2,6.6-3.3,10c-0.8,2-1.6,4.3-2.1,6.4c-0.6,2.5-1.6,5.1-2.5,7.6
		l-0.6,2.5c-1,3.7-2.1,7.2-3.1,10.9l-0.2,1.4c-0.4,2-0.8,3.9-1.2,5.7c-0.2,0.6,0,1.2-0.2,1.8c-0.4,0.8-0.2,1.6-0.2,2.3
		c-0.4,0.8-0.4,1.6-0.2,2.3c0.2,0.6,1,1,1.4,0.6c1-0.8,2.1-1.8,2.9-2.7c5.7-6,10.7-12.3,14.8-19.5c2.1-3.5,4.1-7,6.1-10.5
		c1.2-1.8,2-3.7,2.9-5.7c2.1-4.5,4.1-9,5.9-13.7c1.8-4.3,3.3-8.6,4.7-12.9c2.3-6.8,4.1-13.9,5.5-21.3c1.4-7.6,2.5-15.2,3.7-22.8
		c0.2-1.6,0.4-2.9,1-4.3c0.8-2.1,2.3-3.7,4.7-4.3l3.7-0.8c7.4-1,15-2,22.4-2.9l7.6-0.6c1.4,0,2.5,0.8,3.5,1.6
		C796.9,169.8,796.9,170.4,797.1,170.6z M703.2,277.7c0.4-0.4,0.4-1,0.4-1.4c1-2.9,1.8-5.9,2.1-8.8c0.4-0.6,0.4-1,0.4-1.6
		c0.4-0.4,0.4-1,0.4-1.6c0.2-0.2,0.4-0.6,0.4-1c0.2-0.4,0.4-1,0.4-1.4c0.4-0.8,0.8-1.4,0.8-2.3l-0.2-0.2h0.2c0.2-0.4,0.4-1,0.4-1.6
		c0.2-0.2,0.2-0.4,0.4-0.8s0.4-1,0.4-1.6c0.2-0.2,0.2-0.6,0.2-0.8c0.4-0.6,0.6-1.2,0.6-1.8c0.2-0.2,0.2-0.6,0.2-1
		c0.2-0.2,0.4-0.6,0.6-1c0-0.4,0.2-1,0.4-1.4c0-0.4,0.2-0.6,0.2-1c0.6-0.8,0.8-1.6,1-2.5c0.2-0.2,0.2-0.4,0.2-0.8
		c0.6-0.8,0.8-1.6,1-2.5c0.2-0.2,0.2-0.6,0.2-1v0.2c0.6-0.8,1-1.8,1-2.7c0.2-0.2,0.2-0.6,0.4-1c0.2-0.2,0.2-0.6,0.4-1
		c0.6-1,1-2.1,1.2-3.3c0.2-0.2,0.2-0.6,0.4-1c0-0.4,0.2-0.6,0.4-1s0.4-0.6,0.4-1c0.6-0.8,0.8-1.6,0.8-2.5c0-0.2,0.2-0.6,0.4-1
		c0.2-0.2,0.2-0.6,0.4-1c0.2-0.2,0.2-0.6,0.4-1c0-0.2,0.2-0.6,0.4-1c0-0.2,0.2-0.6,0.4-1l0.4-1c0.6-1,1.2-2.1,1.2-3.3
		c0.4-0.4,0.4-0.8,0.4-1c0.4-0.4,0.4-0.8,0.4-1c0.2-0.4,0.4-0.8,0.4-1c0.2-0.4,0.4-0.8,0.4-1c0.2-0.4,0.4-0.8,0.6-1h-0.2
		c0.2-0.4,0.4-0.8,0.6-1h-0.2c0.2-0.4,0.4-0.8,0.6-1c0-0.4,0.2-0.8,0.4-1c0.2-0.4,0.4-0.6,0.4-1c0.2-0.4,0.4-0.6,0.4-1
		c0.2-0.4,0.2-0.8,0.4-1c0-0.4,0.2-0.8,0.4-1v-0.2c0-0.2,0.2-0.6,0.4-0.8h-0.6c0.2,0.2,0.2,0.6,0.2,0.8c-0.2-0.2-0.4-0.4-0.4-0.8
		c0,0,0.2,0,0.2-0.2c0-0.2,0.2-0.4,0.2-0.8h0.2c0.2,0.2,0.2,0.6,0.2,0.8c0.2-0.2,0.2-0.6,0.4-1c0.2-0.2,0.2-0.6,0.4-0.8
		c0.2-0.4,0.2-0.8,0.4-1c0.2-0.4,0.4-0.8,0.4-1.2v0.2c0.2-0.4,0.4-0.8,0.4-1.2v0.2c0.2-0.4,0.4-0.8,0.4-1.2v0.2l0.6-1.2l-0.2,0.2
		l0.6-1.2l-0.2,0.2l0.6-1.2v0.2c0-0.4,0.2-0.8,0.4-1.2v0.2c0-0.4,0.2-0.8,0.4-1.2v0.2c0-0.4,0.2-0.8,0.4-1.2c0-0.2,0.2-0.6,0.4-1
		v0.2c0.2-0.4,0.2-0.8,0.4-1.2c0.4-0.2,0.4-0.6,0.4-1v0.2c0.8-0.8,1-1.6,1.2-2.5c0.2-0.4,0.4-0.6,0.4-1c0.2-0.4,0.4-0.8,0.4-1H735
		c0-0.4,0-0.6,0-1h0.4c0,0.2,0,0.6,0.2,0.8c0-0.2,0.2-0.6,0.4-0.8c0-0.4,0.2-0.8,0.4-1c0-0.4,0.2-0.6,0.4-1c0-0.4,0.2-0.6,0.4-1
		l0.4-1c0.2-0.2,0.4-0.6,0.4-1l0.4-0.6c0.2-0.4,0.2-0.6,0.4-1c0.2-0.4,0.4-0.6,0.4-1c0.2-0.4,0.4-0.6,0.4-1c0.2-0.2,0.4-0.6,0.4-1
		c0.2-0.2,0.4-0.6,0.4-1c0.2-0.2,0.4-0.6,0.6-1c-0.2,0-0.4-0.2-0.6-0.4h0.4c0-0.4,0-0.6,0-1h0.4c0-0.4,0-0.6,0-1h0.4
		c0-0.4,0-0.8,0-1c-0.2,0.2-0.2,0.6-0.4,1c-0.4,0.2-0.4,0.6-0.4,1l-0.4,1c-0.4,0.2-0.4,0.6-0.4,1c-0.2,0.2-0.4,0.6-0.4,1
		c-0.6,0.4-0.8,0.8-1,1.4h0.6c0,0.4,0,0.6,0,1h-0.4c0-0.2-0.2-0.6-0.2-0.8c0,0.2-0.2,0.6-0.4,0.8h0.6c0,0.4,0,0.6,0,1h-0.4
		c0-0.2-0.2-0.6-0.2-0.8c-0.2,0.2-0.4,0.4-0.4,0.8c-0.4,0.2-0.4,0.6-0.4,1c-1.4,1.6-2,3.5-2.3,5.5c-0.4,0.2-0.6,0.6-0.4,1h0.4
		c0,0.2,0,0.6,0,1h-0.4c0-0.4,0-0.6-0.2-1c-0.2,0.2-0.4,0.6-0.4,1c-0.4,0.2-0.4,0.6-0.4,1c-0.2,0.2-0.4,0.6-0.4,1
		c-0.8,0.4-1,1.2-1.2,2l-4.7,10.9c-0.2,0.6-0.6,1.4-0.8,2.1c-0.2,0.2-0.2,0.6-0.4,1c-0.4,0.6-0.8,1.4-1,2.1
		c-2.3,5.7-4.5,11.3-6.8,17c-0.6,1.8-1.4,3.3-1.8,5.3c-1.6,3.5-2.7,7-4.1,10.5c-3.3,9.8-6.6,19.5-9,29.5c-1,3.3-1.8,6.8-2,10.3
		c-0.2,0.2-0.2,0.4-0.2,0.6v0.2c0.2,0.2,0.2,0,0.2-0.2v-0.6c0.2,0,0.4,0,0.6,0c-0.2-0.8,0.8-1,0.6-1.8
		C702.4,278.7,703.6,278.5,703.2,277.7z M705,287.3c1-7.8,2.9-15.2,5.1-22.6c3.7-12.9,8.2-25.4,12.9-37.9
		c5.5-14.4,11.5-28.7,17.6-42.7c1.8-3.9,3.3-7.8,5.1-11.7c1.8-3.3,3.1-6.8,4.7-10.1c0.2-0.2,0-0.6,0.2-0.8c1-1.4,1.6-2.7,2-4.5
		c0.6-0.4,0.8-1.2,0.8-1.8c-0.6,0.4-0.8,1-1,1.8c-1,1.2-1.6,2.7-2,4.3c-0.6,0.4-0.8,1-1,1.4c-0.6,1.2-1.2,2.3-1.6,3.5
		c-1,2-1.8,3.9-2.5,5.9c-0.4,0.6-0.8,1.2-1,2c-2.3,5.1-4.7,10.1-6.8,15.2c-5.3,12.5-10.5,25-15.4,37.9c-4.9,12.9-9.4,25.8-13.3,39
		c-1.6,5.5-2.9,11.1-3.9,17c-0.2,1.8-0.6,3.5-0.4,5.5c0.2-0.2,0.4-0.2,0.4-0.4C705,287.9,705,287.5,705,287.3z M708.7,257.1l0.2,0.2
		h-0.2V257.1z M717.2,231.3c0,0.4,0,0.6,0.2,1c-0.2-0.4-0.4-0.6-0.4-1H717.2z M720.4,222.9c0.2,0.4,0.2,0.6,0.2,1H720
		C720.2,223.7,720.4,223.3,720.4,222.9z M723.9,273.8c-0.4,0.4-0.8,0.8-0.8,1.4C723.7,274.8,723.9,274.4,723.9,273.8z M725.8,271.5
		c0.2,0,0.2-0.2,0.4-0.2l0.4-0.6c0,0.2,0,0.4,0,0.6l0.6-0.6c0-0.2,0.2-0.6,0.2-0.8l0.6-0.6c0.2-0.4,0.2-0.6,0.4-1
		c0.2-0.2,0.2-0.4,0.4-0.4c-0.2,0-0.2,0-0.4-0.2h0.4c0.6-0.2,0.8-0.8,1-1.4c0.4-0.4,0.6-1,0.8-1.6c0.2-0.2,0.2-0.6,0.4-0.8l-0.4-0.6
		c0.2,0,0.2,0,0.4,0v0.4c0.2,0,0.4-0.2,0.4-0.4c0.2-0.4,0.4-0.6,0.4-1c-0.2-0.2-0.2-0.4-0.4-0.4c-0.6,0.2-1,0.8-1,1.4
		c-0.6,0.4-0.8,0.8-0.8,1.4c-1,0.8-1.4,1.8-2,2.9c-0.8,1-1.6,2.1-2,3.5c-0.6,0.4-1.2,1.2-1.2,2C725.2,272.9,725.6,272.3,725.8,271.5
		z M738.9,254.9c-0.8,0.4-1.2,1.2-1.4,2.1c-0.2,0.4-0.4,0.6-0.4,1c-0.8,0.6-1,1.6-1.4,2.3l-4.7,8.2c-1,1.6-2,3.3-2.9,5.1
		c-0.8,0.8-1.4,1.6-1.6,2.5c-0.8,0.4-1,1.2-1.2,2c0.6-0.6,1-1.2,1.2-2c1-0.4,1.6-1.4,2-2.3c0.6-0.4,1-1.2,1.4-1.8l4.1-7
		c0.8-1.4,1.8-2.7,2.1-4.3c0.6-0.8,1.2-1.6,1-2.7c0.4-0.2,0.6-0.4,0.6-0.8C738.5,256.7,738.7,255.7,738.9,254.9z M728.2,268
		c0,0.2,0.2,0.2,0.2,0.4L728,268H728.2z M730.1,265.1h-0.4C729.7,264.9,729.9,264.9,730.1,265.1z M731.3,261.9c0.2,0,0.2,0,0.4,0
		c0-0.2,0-0.6-0.2-0.8L731.3,261.9z M731.7,261h0.4c0.2,0,0.4-0.2,0.6-0.4c-0.2-0.4-0.2-0.6-0.2-1h0.4c0-0.4,0-0.6,0-1l-0.4,1
		C732.1,260,731.7,260.4,731.7,261z M734.2,188.7c0,0,0.2,0,0.4,0v1c-0.2,0-0.2,0-0.4,0V188.7z M737.9,181.9
		c-0.2-0.2-0.4-0.4-0.6-0.6c0.2,0.2,0.4,0,0.6,0V181.9z M737.9,181.3c-0.2-0.2-0.2-0.6-0.2-1h0.6c0,0.4,0,0.6,0,1H737.9z M741.4,172
		c0.2,0,0.4,0.2,0.4,0.2l0.6-0.6c0-0.6,0.2-1.2,0.4-1.8C742,170.4,741.6,171.2,741.4,172z M743,168.6h0.6c0-0.4-0.2-0.6-0.2-1
		C743.4,168.1,743.2,168.3,743,168.6z M744,166.7c0.4-0.4,0.6-0.8,0.8-1.4h0.4c0-0.4,0-0.6,0-1h0.4c0-0.4,0-0.6,0-1l-0.4,1
		c-0.2,0.4-0.2,0.6-0.4,1C744.2,165.7,744,166.1,744,166.7z M747.3,159.9h0.4c0-0.2,0-0.6,0-0.8
		C747.5,159.3,747.3,159.7,747.3,159.9z M753.2,155c0.6-0.2,0.6-0.8,0.8-1.4C753.6,154,753.2,154.4,753.2,155z M754.3,152.4
		c0.4-0.2,0.4-0.4,0.4-0.8C754.5,151.9,754.3,152.1,754.3,152.4z M754.9,151.5c0.6-0.4,0.8-1.4,1.2-2.1
		C755.3,149.9,754.9,150.7,754.9,151.5z M758.8,175.1c-0.8,0.2-0.8,0.4-0.4,1C758.4,175.7,758.6,175.5,758.8,175.1z M760.4,178h-0.2
		c0,0.2,0,0.4,0,0.4h0.2C760.6,178.2,760.6,178.2,760.4,178z"
      />
    </g>
    <style jsx>
      {`
        #logo {
          width: 200px;
          top: 7.3vh;
          position: ${color === "#fff" ? "fixed" : "static"};
        }
        #logo g {
          transition: fill 1s ease;
        }

        @media only screen and (max-width: 600px) {
          #logo {
            width: 100px;
          }
        }
      `}
    </style>
  </svg>
)

export default Logo
